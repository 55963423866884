@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
html {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Montserrat', sans-serif, Poppins, 'Open Sans', 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace, Poppins, 'Open Sans', 'Montserrat', sans-serif;
}

.table-scroll {
  width: 100%;
  overflow: auto;
}

:root {
  --primary: #2d6aa0;
  height: 100% !important;
  --blue: #009FE3;
  --golden: rgb(232, 168, 56);
  --primary: var(--blue);
  --line-border-empty: #e1e1e1;
  --success: #28a745;
  --white: #FFFFFF;
  --black: #000000;
  --shade-AEAEAE: #AEAEAE;
  --shade-EBEBEB: #EBEBEB;
  --shade-cdcbcb: #cdcbcb;
  --shade-c7c7c7: #c7c7c7;
}

.text-primary {
  color: #009FE3 !important;
  color: var(--primary) !important;
}

.bg-primary {
  background-color: #009FE3 !important;
  background-color: var(--primary) !important;
}

.btn-primary {
  background-color: #009FE3 !important;
  background-color: var(--primary) !important;
}

.btn-danger {
  background-color: #f00 !important;
}

.btn-custom {
  padding: 10px 20px;
}

.text-custom-grey {
  color: #A8A8A8;
  font-weight: 500;
}

.text-custom-greyDark {
  color: #5A5858;
  font-weight: 500;
}

.text-custom-greyLight {
  color: #E1E1E1;
  font-weight: 500;
}

.break-text {
  word-wrap: break-word;
  /* word-break: break-all; */
}

/* gloabl font size */
.f-32 {
  font-size: 2.5rem !important;
}

.f-18 {
  font-size: 1.2rem !important;
}

.f-16 {
  font-size: 1rem !important;
}

.f-14 {
  font-size: 0.9rem !important;
}

.f-12 {
  font-size: 0.7rem !important;
}

.f-10 {
  font-size: 0.6rem !important;
}

.f-9 {
  font-size: 0.5rem !important;
}

.curPoint {
  cursor: pointer;
}

.noBorder {
  border: none !important;
}

.table-scroll {
  width: 100%;
  overflow: auto;
}

.initial-div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.initial-contain {
  height: 100%;
  overflow-y: auto;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* margin-top: 3.7rem !important; */
}

.select-ht {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 766px) {
  .initial-contain {
    overflow-y: auto;
  }
}

/* ------------------------loader----------------------------------- */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 12px !important;
}

.MuiInputBase-input {
  margin-top: 0px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiIconButton-root {
  padding: unset !important;
}

.MuiTableCell-root {
  font-size: unset !important;
}

.MTableHeader-header-6 {
  font-size: 0.8rem !important;
}

.MuiAccordionDetails-root {
  display: block !important;
}

#onesignal-bell-container {
  bottom: 30px !important;
  right: -15px !important;
}

.modalPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

.f-12 {
  font-size: 0.7rem !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* sample font size */

.defaultFont {
  font-size: 1rem !important;
}

.heading-spec {
  font-size: 3.5rem;
}

.heading1 {
  font-size: 3rem;
}

.subheading1 {
  font-size: 1rem;
}

.heading2 {
  font-size: 2.5rem;
}

.subheading2 {
  font-size: 1.2rem;
}

.heading3 {
  font-size: 2rem;
}

.heading4 {
  font-size: 1.5rem;
}

.subContent {
  font-size: 0.8rem;
}

.helpBtn {
  font-size: 1.5rem;
}

@media only screen and (max-width: 300px) {
  .heading1 {
    font-size: 1.5rem !important;
  }

  .subheading1 {
    font-size: 1rem !important;
  }

  .heading2 {
    font-size: 1.5rem !important;
  }

  .heading3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .heading1 {
    font-size: 2rem !important;
  }

  .heading4 {
    font-size: 1.3rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .subheading1 {
    font-size: 1.5rem !important;
  }

  .heading2 {
    font-size: 3rem !important;
  }
}

/* sample font size */
.mainDiv {
    margin: 1rem 4rem;
    font-family: Poppins;
}

.animBtn {
    background-color: var(--line-border-fill);
    color: #fff;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
}

.lightColorText {
    color: #AEAEAE;
}

.welcomeLoginForm {
    margin-left: 4rem;
    margin-right: 2rem;
}

.welcomeImg {
    margin-left: 3rem;
}

.welcome-heading {
    font-size: 2.1rem !important;
    font-weight: 700;
}

.welcome-sub-heading {
    top: -10px !important;
    color: #AEAEAE !important;
    position: relative !important;
    line-height: 1.2;
    margin-top: 0.5rem !important;
}

.loginForm {
    margin-top: 2rem;
    margin-right: 4rem;
}

.login-screen-input {
    padding-left: 10px;
    line-height: 2 !important;
    background-color: transparent;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    align-items: center;
    display: flex;
}

.signIn-button {
    padding-left: 10px;
    line-height: 2 !important;
    background-color: #009FE3;
    color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(0, 159, 227, 0.25);
    align-items: center;
    width: 100%;
    font-size: 1.2rem;

}

.googleSignIn-button {
    padding-left: 10px;
    line-height: 2.8 !important;
    background-color: transparent;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 700;
}

.noAccount {
    color: #AEAEAE;
    font-weight: bold;
    text-align: center;
}

.investDiv {
    /* margin-top: 3rem; */
    margin-left: 4rem;
    margin-right: 4rem;
    /* margin-bottom: 4rem; */
    text-align: center;
    display: block;
}

.investDetail {
    color: #AEAEAE;
    font-size: 1.2rem;
}

.featuredInImage {
    width: 100%;
    object-fit: contain;
    height: 3rem;
}

.howItWorksBox {
    justify-content: right !important;
    display: flex;
}

.aboutCont {
    margin-right: 2rem;
}

.aboutTextpace {
    margin: 4rem 0rem !important;
}

.howItWorks {
    width: 80%;
    border: 2px solid #EBEBEB;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.howItWorksCont {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
}


.howItWorksStepLine {
    border-left: 2px solid #EBEBEB;
    height: 5rem;
    margin-left: 1.7rem !important;
    display: flex;
    align-items: center;
}

.howItWorksStepCircle {
    height: 10px;
    width: 10px;
    background-color: #EBEBEB;
    border-radius: 50%;
    position: relative;
    left: -6px;
}

.getInTouch {
    font-size: 0.9rem;
    margin: 3rem 10rem;
}

.getInTouchIcon {
    width: unset;
}

.footer {
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
    display: block;
}

.footerHeading {
    background: linear-gradient(135deg, #009FE3 0%, #009FE3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold;
}

.footerConv {
    margin-top: 0rem;
}

.hr {
    height: 0.5px;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 50.52%, rgba(0, 0, 0, 0) 100%);
    margin-top: 3rem !important;
}

.arrowDivLeft {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 25%;
}

.arrowDivRight {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 60%;
}

.blueBack {
    position: absolute;
    width: 50%;
    height: 15rem;
    background-color: rgb(161, 217, 244, 0.4);
    -webkit-filter: blur(8rem);
            filter: blur(8rem);
    border-radius: 65rem;
    z-index: -1;
    right: 0;
}

.redBack {
    position: absolute;
    width: 50%;
    height: 15rem;
    background-color: rgb(237, 31, 36, 0.08);
    -webkit-filter: blur(8rem);
            filter: blur(8rem);
    border-radius: 65rem;
    z-index: -1;
    left: 0px;
}

.distDetailBannerBg {
    background: rgba(248, 248, 248, 0.77);
    -webkit-backdrop-filter: blur(9px);
            backdrop-filter: blur(9px);
    border-radius: 16px;
    width: 45vw;
    z-index: 99;
    margin-left: 4rem !important;
    position: relative;
    font-family: Poppins !important;
    padding: 2rem !important;

}

.distDetailBannerTitle {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
}

.distDetailBannerBgP {
    padding-top: 1rem !important;
    font-size: 1.2rem !important;
}

.distDetailBannerul {
    -webkit-padding-start: 20px !important;
            padding-inline-start: 20px !important;
}

.partnerUsBtn {
    background-color: #69CDF9;
    color: #FFFFFF;
    border-color: #69CDF9;
    border-radius: 5px;
    padding: 0.5rem 1.5rem !important;
    font-weight: bold;
    font-size: 1.2rem !important;
}

.getTouchBtn {
    background-color: #FFFFFF;
    color: #69CDF9;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 0.5rem 1.5rem !important;
    font-weight: bold;
    font-size: 1.2rem !important;
}

.partnerWithCard {
    background: #FFFFFF;
    box-shadow: 4.06905px 16.2762px 40.6905px rgba(74, 153, 211, 0.2);
    border-radius: 8.1381px;
    margin: 2rem 2rem 0.5rem 2rem !important;
    padding: 0rem 2rem 2rem 2rem !important;
    font-size: 0.7rem !important;
    height: 90%;
}

.partnerWithCardTitle {
    font-size: 0.9rem !important;
}

.partnerWithCardIcon {
    object-fit: contain;
    width: 8rem;
    height: 8rem;
}

.partnerWithImage {
    object-fit: contain;
    width: 100% !important;
}

.ourPartner {
    background: rgba(74, 153, 211, 0.1) !important;
    margin: 5rem 0 !important;
    padding: 3rem !important;
}

.ourPartnerIcon {
    object-fit: contain;
    width: 4rem !important;
    height: 4rem !important;
}

.ourPartnerTitle {
    padding-top: 0 !important;
    font-size: 1.5rem !important;
}

.aboutCard {
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 1.5rem !important;
    padding: 2rem 2rem 2rem 2rem !important;
    font-size: 0.7rem !important;
}

.aboutCardTitle {
    padding: 2rem 0 0 0 !important;
    font-size: 1.2rem !important;
    color: rgba(0, 41, 71, 0.59);
    font-weight: bold !important;
}

.aboutCardIcon {
    object-fit: contain;
    width: 4rem;
    height: 4rem;
}

.howDoesNumber {
    background-color: #69CDF9;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: bold;
}

.getTouchPad {
    padding: 1.5rem 1.5rem !important;
    height: 100%;
    border: 1px solid #F9FBFB;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    background-color: #FFFFFF;

}

.touchPadding {
    padding: 3rem 3rem 1.5rem 3rem !important;
}

.sendBtn {
    background-color: #69CDF9;
    color: #FFFFFF;
    width: 100%;
    line-height: 2.5;
    text-align: center;
    border-radius: 5px;
    font-size: 1rem;
}

.faqOpen {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    background-color: #52a952;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 1.5rem !important;

}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;

    border-top: 1rem solid #ebebeb;
    position: fixed;
    bottom: 4rem;
    right: 2.5rem;
    z-index: 9999;
}

.faqMain {
    bottom: 4.75rem;
    right: 2rem;
    position: fixed;
    z-index: 9999;
    width: 40vw;
    height: 70vh;
    border: 1px solid #ebebeb;
    border-top: 3px solid #52a952;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.faqWA {
    background-color: antiquewhite;
    margin-bottom: 2rem;
    padding: 2rem 1rem;
    text-align: center;
}

.faqWAImg {
    width: 3rem !important;
}

.faqSet {
    padding: 0 1.5rem 2rem !important;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.faqSet::-webkit-scrollbar {
    display: none;
}

#faq .MuiAccordion-root {
    margin-bottom: 0.5rem !important;
}

#faq .MuiAccordionSummary-content {
    margin: 0 !important;
}

#faq .MuiAccordionSummary-content .MuiTypography-body1 {
    font-weight: bold !important;
}

#faq .MuiAccordionSummary-content.Mui-expanded {
    padding-top: 16px !important;
}

#faq .MuiTypography-body1 {
    font-size: 0.7rem !important;
}

#faq .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}

@media only screen and (max-width: 992px) {

    .distDetailBannerBg {
        background: rgba(248, 248, 248, 0.77);
        -webkit-backdrop-filter: blur(9px);
                backdrop-filter: blur(9px);
        border-radius: 16px;
        z-index: 99;
        margin: 1.5rem !important;
        position: relative;
        font-family: Poppins !important;
        padding: 1rem !important;
    }

    .distDetailBannerTitle {
        font-size: 1.4rem !important;
    }

    .distDetailBannerBgP {
        padding-top: 1rem !important;
        font-size: 0.9rem !important;
    }

    .distDetailBannerul {
        -webkit-padding-start: 20px !important;
                padding-inline-start: 20px !important;
    }

    .partnerUsBtn {
        font-size: 0.9rem !important;
    }

    .getTouchBtn {
        font-size: 0.9rem !important;
    }

    .mainDiv {
        margin: 1rem 2rem;
    }

    .welcomeLoginForm {
        margin-left: 2rem;
        margin-right: 0rem;
    }

    .welcome-heading {
        font-size: 1.6rem !important;
    }

    .welcome-sub-heading {
        top: -5px !important;
        position: relative !important;
        line-height: 1.2;
    }

    .loginForm {
        margin-top: 2rem;
        margin-right: 1rem;
    }

    .welcomeImg {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .investDiv {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .investDetail {
        font-size: 0.8rem;
    }

    .howItWorks {
        width: 100%;
    }

    .howItWorksCont {
        margin-left: 1rem;
    }

    .getInTouch {
        font-size: 0.9rem;
        margin: 3rem 4rem;
    }

    .footer {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .blueBack {
        height: 25rem;
    }

    .redBack {
        height: 25rem;
    }

    .partnerWithCard {
        margin: 2rem 1rem 0.5rem 1rem !important;
        padding: 0rem 1rem 1rem 1rem !important;
        font-size: 0.5rem !important;
        height: 80% !important;
    }

    .partnerWithCardTitle {
        font-size: 0.7rem !important;
    }

    .partnerWithCardIcon {
        width: 6rem !important;
        height: 6rem !important;
    }

    .aboutCard {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        margin: 1rem !important;
        padding: 1.5rem !important;
        font-size: 0.7rem !important;
    }

    .aboutCardTitle {
        padding: 2rem 0 0 0 !important;
        font-size: 1.2rem !important;
        color: rgba(0, 41, 71, 0.59);
        font-weight: bold !important;
    }

    .aboutCardIcon {
        object-fit: contain;
        width: 4rem;
        height: 4rem;
    }

    .touchPadding {
        padding: 1.5rem !important;
    }

    .faqMain {
        width: 60vw !important;
        height: 60vh !important;
    }

    .faqSet {
        padding: 0 1rem 1rem !important;
    }

    #faq .MuiTypography-body1 {
        font-size: 1rem !important;
    }

}

@media only screen and (max-width: 768px) {
    .distDetailBannerBg {
        background: rgba(248, 248, 248, 0.77);
        -webkit-backdrop-filter: blur(9px);
                backdrop-filter: blur(9px);
        border-radius: 16px;
        width: unset !important;
        z-index: 99;
        margin: 1rem !important;
        position: relative;
        font-family: Poppins !important;
        padding: 1rem !important;

    }

    .distDetailBannerTitle {
        font-size: 1.2rem !important;
    }

    .distDetailBannerBgP {
        padding-top: 1rem !important;
        font-size: 0.9rem !important;
    }

    .distDetailBannerul {
        -webkit-padding-start: 20px !important;
                padding-inline-start: 20px !important;
    }

    .partnerUsBtn {
        font-size: 0.9rem !important;
        font-weight: unset !important;
        padding: 0.5rem 0.7rem !important;
    }

    .getTouchBtn {
        font-size: 0.9rem !important;
        font-weight: unset !important;
        padding: 0.5rem 0.7rem !important;
    }

    .mainDiv {
        margin: 1rem 1rem;
    }

    .welcomeLoginForm {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .welcome-heading {
        font-size: 1.4rem !important;
    }

    .welcome-sub-heading {
        top: -5px !important;
        position: relative !important;
        line-height: 1.2;
    }

    .loginForm {
        margin-top: 2rem;
        margin-right: 1rem;
    }

    .welcomeImg {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .investDiv {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .investDetail {
        text-align: justify;
    }

    .aboutCont {
        margin-right: 0rem;
    }

    .aboutTextpace {
        margin: 2rem 0rem !important;
    }

    .getInTouch {
        font-size: 0.6rem;
        margin: 1.5rem 0rem;
    }

    .getInTouchIcon {
        width: 1.8rem;
    }

    .footer {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .footerConv {
        margin-top: 2rem !important;
    }

    .blueBack {
        height: 35rem;
    }

    .redBack {
        height: 35rem;
    }

    .partnerWithCard {
        margin: 0.5rem 0.5rem 0.5rem 0.5rem !important;
        padding: 0rem 0.5rem 0.5rem 0.5rem !important;
        font-size: 0.5rem !important;
        height: 90% !important;
    }

    .partnerWithCardTitle {
        font-size: 0.7rem !important;
    }

    .partnerWithCardIcon {
        width: 4rem !important;
        height: 4rem !important;
    }

    .ourPartner {
        padding: 1rem !important;
    }


    .ourPartnerIcon {
        object-fit: contain;
        width: 2rem !important;
        height: 2rem !important;
    }

    .ourPartnerTitle {
        padding-top: 0.5rem !important;
        font-size: 1rem !important;
    }

    .aboutCard {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        margin: 0.5rem !important;
        padding: 1rem !important;
        font-size: 0.7rem !important;
        height: 90%;
    }

    .cardWidth {
        width: 50%;
    }

    .aboutCardTitle {
        padding: 1rem 0 0 0 !important;
        font-size: 0.9rem !important;
        color: rgba(0, 41, 71, 0.59);
        font-weight: bold !important;
    }

    .aboutCardIcon {
        object-fit: contain;
        width: 2rem;
        height: 2rem;
    }

    .getTouchPad {
        padding: 1.5rem 0 !important;
    }

    .touchPadding {
        padding: 1rem !important;
    }

    .faqOpen {
        bottom: 4rem !important;
        right: 0.5rem !important;
    }

    .faqMain {
        width: 95vw !important;
        height: 70vh !important;
        bottom: 7.75rem !important;
        right: 0.5rem !important;
    }

    .arrow-down {
        bottom: 7rem;
        right: 1rem;
    }

    .faqSet {
        padding: 0 1rem 1rem !important;

    }

    #faq .MuiTypography-body1 {
        font-size: 0.8rem !important;
    }
}

.loginForm .MuiInput-underline::before {
    border-bottom: 0px !important;
}

.loginForm .MuiInput-underline::after {
    border-bottom: 0px !important;
}

/* -------------------------------------------------old=------------------------------------ */
/* .login-screen-form-wrapper{
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 10px;
}
.login-screen-wallpaper {
    height: 100%;
}
.login-screen-wallpaper > img {
    object-fit: cover;
}
.login-screen-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 150px;
}
.login-screen-input {
    padding-left: 0;
    margin-bottom: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #E1E1E1;
    border-radius: 0;
}
.login-screen-input:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.login-screen-hr {
    margin: 0% !important;
}
.login-screen-hr-or {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
}
.login-screen-hr-or > p {
    position: absolute;
    width: 80px;
    padding: 0;
    margin: 0;
    top: -10px;
    background-color: white;
}

.google-sign-in-button {
    background-color: #f2f2f2;
    padding: 8px 30px;
    border-radius: 30px;
    white-space: normal !important;
    word-wrap: break-word;
}
.google-sign-in-button > img {
    width: 20px;
    margin-bottom: 3px;
    margin-right: 5px;
}
@media screen and (max-width: 576px) {
    .login-screen-sign-in-button {
        width: 100%;
        margin: auto;
        padding: 10px 0px;
        margin: 0px 0px 20px 0px;
        border-radius: 30px !important;
    }
} */
#test {
  --marquee-width: 100vw;
  /* --marquee-elements: 12; */
  /* defined with JavaScript */
  --marquee-elements-displayed: 6;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  color: #111;
  overflow: hidden;
  position: relative;
}

.marquee:before, .marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  /* background: linear-gradient(to right, #fff 0%, transparent 100%); */
}

.marquee:after {
  right: 0;
  /* background: linear-gradient(to left, #fff 0%, transparent 100%); */
}

.marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  -webkit-animation: scrolling var(--marquee-animation-duration) linear infinite;
          animation: scrolling var(--marquee-animation-duration) linear infinite;
  margin-bottom: 0;
}

/* .marquee-content:hover {
    animation-play-state: paused;
  } */
@-webkit-keyframes scrolling {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
            transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
  }
}
@keyframes scrolling {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
            transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
  }
}

.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  /* 5rem; */
  white-space: nowrap;
}


.animCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #AEAEAE;
  font-weight: bold;
}

.animImgCard {
  width: 6rem;
  height: 6rem;
  border: 2px solid #EBEBEB;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: #fff;

}

.animImg {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

@media (max-width: 992px) {

  #test {
    --marquee-width: 100vw;
    --marquee-elements-displayed: 4;
  }

  .marquee:before, .marquee:after {
    width: 5rem;
  }
}

@media (max-width: 768px) {

  #test {
    --marquee-width: 98vw;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0.05rem;
    margin-right: 0.05rem;
    --marquee-elements-displayed: 3;
  }

  .marquee:before, .marquee:after {
    width: 5rem;
  }

  .animCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #AEAEAE;
    font-weight: bold;
  }

  .animImgCard {
    width: 4rem;
    height: 4rem;
    border: 2px solid #EBEBEB;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    background-color: #fff;

  }
}


/* sign in animation--------------------------------------------------------------------- */

.gSignIn-parent {
  height: 2.5rem !important;
  /* background-color: #009FE3; */
  color: #fff;
  display: flex;
  justify-content: center;
}

.gSignIn-Anim {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #009FE3;
  color: #009FE3;
  box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  -webkit-animation: dotTyping 1.5s infinite linear;
          animation: dotTyping 1.5s infinite linear;
}

@-webkit-keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  50% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px -10px 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px -10px 0 0 #009FE3;
  }

  100% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  50% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px -10px 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px -10px 0 0 #009FE3;
  }

  100% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }
}
.kyc-container {
  display: flex;
  width: 80%;
  /* margin-top: 57px; */
}

.kyc-main {
  /* height: calc(100vh - 60px); */
  overflow: auto
}

.text-theme {
  color: #2d6aa0;
  font-weight: 500;
}

.form-control {
  font-size: 10px;
}

.panel-card {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hr-kyc {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.alert {
  padding: 5px;
}

.number-curve {
  background-color: #eeeeee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  text-align: center;
  margin-right: 10px;
}

.number-heading {
  width: calc(100% - 60px);
}

.head-number-active {
  font-size: 20px;
  font-weight: 700;
  color: #2d6aa0 !important;
}

.head-number-next {
  font-size: 20px;
  font-weight: 700;
  color: #5a5858 !important;
}

.head-number-prev {
  font-size: 20px;
  font-weight: 700;
  color: #999 !important;
}

.main-heading-active {
  color: #2d6aa0 !important;
  font-size: 16px;
  font-weight: 600;
}

.main-heading-prev {
  color: #999 !important;
  font-size: 16px;
  font-weight: 600;
}

.main-heading-next {
  color: #5a5858 !important;
  font-size: 16px;
  font-weight: 600;
}

.sub-heading {
  font-size: 12px;
  color: #a8a8a8;
}

.main-kycPanel {
  background-color: #eeeeee;
  padding: 10px 20px;
  overflow: auto;
  /* height: calc(100vh - 60px); */
  width: 100%
}

@media only screen and (max-width: 766px) {
  .kyc-container {
    width: 100%;
  }
  /* .main-kycPanel {
    height: calc(100vh - 110px);
  } */
}

.custom-link {
  cursor: pointer;
}

.custom-link-active {
  color: #2d6aa0;
}

.custom-link-active:hover {
  color: #2d6aa0;
  text-decoration: none;
}

.custom-link-next {
  color: #5a5858;
}

.custom-link-next:hover {
  color: #5a5858;
  text-decoration: none;
}

.custom-link-prev {
  color: #999;
}

.custom-link-prev:hover {
  color: #999;
  text-decoration: none;
}

.custom-link:hover {
  color: #2d6aa0;
  text-decoration: none !important;
}

/* -------------------------- */
:root {
  --line-border-fill: #2d6aa0;
  --line-border-empty: #e1e1e1;
}

.progress-body {
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.progress-container::before {
  content: "";
  background: #e1e1e1;
  background: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 350px;
  z-index: 1;
}

.progress {
  background: #2d6aa0;
  background: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  background: #fff;
  color: #999;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #e1e1e1;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
}

.circle.active {
  border-color: #2d6aa0;
  border-color: var(--line-border-fill);
}

.btn {
  background-color: #2d6aa0;
  background-color: var( --line-border-fill);
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  border: 0;
  border-radius: 6px;
  padding: 8px 30px;
  margin: 5px;
  font-size: 14px;
}

.btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: #e1e1e1;
  background-color: var( --line-border-empty);
  cursor: not-allowed;
}

.file-border {
  border-style: dashed;
  border-width: 1px;
  border-color: #999;
  height: auto;
  background-color: #fff;
  text-align: center;
}

.file-border i {
  font-size: 50px;
  color: #999;
}

.file-border label {
  display: inline-block;
  background-color: none;
  color: #2d6aa0;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 0.5rem;
  font-weight: 600;
}

.card-grey {
  background-color: #fff;
  color: #5a5858;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
}

.docs-box {
  height: 120px;
  width: 140px;
  border: 1px dashed #a8a8a8;
  text-align: center;
  padding: 1rem;
  color: #999;
  text-decoration: none;
  vertical-align: middle;
}

.docs-box-hover:hover {
  color: #2d6aa0 !important;
  text-decoration: none;
}

/* custom scroll bar */

.main-kycPanel::-webkit-scrollbar {
  width: 0.3em;
  height: 0.2em;
}

.main-kycPanel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.main-kycPanel::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.docs-box::-webkit-scrollbar {
  width: 0.5em;
}

.docs-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.docs-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* otp model css */

.otp-textField {
  font-size: 20px;
  padding: 10px;
  background-color: #ecebeb;
  border: none;
  border-bottom: 1px solid #a8a8a8;
  text-align: center;
  color: #5a5858;
}
.navbar-container {
    height: 60px;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px !important;
}

.navbar-company-logo-container {
    width: 80px;
}

.navbar-items-container>* {
    margin-left: 40px;
}

.navbar-items-user {
    width: 40px;
}

.navbar-items-link * {
    color: #5a5858;
    font-weight: 500;
}

.navbar-items-link:hover a {
    text-decoration: none !important;
}

/* mobile nav */

.navbar-mobile-items-container>* {
    margin-left: 20px;
}

.navbar-mobile-items-link * {
    color: #2d6aa0;
    font-size: 20px;
    font-weight: 500;
}

.navbar-mobile-items-link:hover a {
    text-decoration: none !important;
}

/* PROFILE MODAL */

.navbar-profile-modal-container {
    position: absolute;
    top: 63px;
    right: 10px;
    width: 280px;
    background-color: red;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    font-size: 12px;
    z-index: 99999;
}

#topbar {
    z-index: 999 !important;
    height: 65px;
}

@media screen and (max-width: 576px) {
    .navbar-profile-modal-container {
        width: calc(100% - 20px);
    }

    #topbar {
        z-index: 999 !important;
        height: 70px;
    }
}

/* NOTIFICATION MODAL */

.notify-modal-container {
    position: absolute;
    top: 63px;
    right: 10px;
    width: 400px;
    font-size: 12px;
    z-index: 99999;
}

@media screen and (max-width: 576px) {
    .notify-modal-container {
        width: calc(100% - 20px);
    }
}

/* notification bell */

.noti-not-read-icon {
    position: absolute;
    background-color: red;
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

.noti-bell-main {
    display: flex;
    align-items: baseline;
    justify-content: end;
}

.unread-not-bg {
    background-color: #e6e6e6 !important;
}

.notiParent {
    padding: 2px;
    background: white;
    border-radius: 7px;
}

/* buy sell bottom bar mobile button */

.buy-sell-mob-btn {
    position: fixed !important;
    bottom: 50px !important;
    width: 100% !important;
}

.btm-bar {
    height: 60px;
}

.btm-bar-hr {
    height: 2rem;
    border: none;
    border-left: 1px solid #dee2e6 !important;
    width: 1px;
}

.btm-bar-space {
    justify-content: space-evenly !important;
}

.screener-btmbtn-container {
    position: fixed !important;
    bottom: 60px !important;
}

/* social media help icon */
.socialmedia-help {
    font-size: 25px;
    z-index: 23432423;
    position: fixed;
    right: 0;
    bottom: 20%;
    color: #2d6aa0;
    background-color: #e6e6e6;
    height: 120px;
    width: 30px;
    text-align: center;
    border-radius: 3px;
}

@media screen and (max-width: 576px) {
    .notify-modal-container {
        width: calc(100% - 20px);
    }

    .socialmedia-help {
        font-size: 25px;
        height: 120px;
        width: 30px;
    }
}

/* custom scroll bar */

.notificationPanel::-webkit-scrollbar {
    width: 0.7em;
    height: 0.2em;
}

.notificationPanel::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.notificationPanel::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* screener custom css */

.screener::-webkit-scrollbar {
    width: 0.3em;
    height: 0.2em;
}

.screener::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.screener::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.impersonating-bar {
    height: 1.2rem;
    font-size: 0.8rem;
    background-color: var(--golden);
    color: var(--white);
    text-align: center;
    max-width: 300px;
    margin: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 0 0.5rem;
}

.impersonating-bar-text {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    margin-right: 0.5rem !important;
}
/* Left Pane Container */

.leftbar-container {
    /* margin-top: 57px; */
    width: 20%;
    /* height: fit-content; */
    flex-direction: column;
    overflow-y: auto;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 1rem;
    background-color: #fff !important;
}

.leftBar-cont {
    border: 1px solid #ebf0f0;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
}

#leftBar .dashCard {
    height: auto;
}

.leftBar-header {
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.leftBar-heading {
    font-size: 0.9rem !important;
    font-weight: bold !important;
}

.leftbar-content {
    padding: 1rem 1rem !important;
    text-align: center !important;
}

.leftBar-header-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    object-fit: contain !important;
}

.leftBar-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leftBar-icon {
    width: 2.5rem !important;
    height: 2.5rem !important;
    object-fit: contain;
}

.leftBar-icon-bg {
    padding: 0.5rem !important;
    text-align: center;
    object-fit: contain;
    background-color: #dcdbed;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem !important;
}

.leftBar-text {
    margin-top: 2rem !important;
}

.leftBar-link {
    margin-top: 0.5rem !important;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: left !important;
    padding: 0.2rem !important;
    word-break: break-all !important;
}

.leftBar-CopyLink {
    background-color: #009FE3;
    color: #fff;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: 100% !important;
    margin: 0.5rem 0 !important
}

.leftBar-CopiedLink {
    background-color: #66d367;
    color: #fff;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: 100% !important;
    margin: 0.5rem 0 !important
}

.leftBar-share {
    padding-top: 1rem !important;
}

.highLight {
    box-shadow: inset 0px -2px 14px 13px rgb(32 207 226 / 46%);
}

/* COMPANY TABLE */

.side-nav-company-table {
    /* margin-top: 30px; */
    /* height: calc(100% - 40px); */
    overflow-x: auto;
    height: 100%;
    display: flex;
}

.side-nav-company-table table {
    border-collapse: unset;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.side-nav-company-table tr {
    height: 2.5rem;
}

.side-nav-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.side-nav-buy-button {
    color: #28a745;
}

.side-nav-sell-button {
    color: #dc3545;
}


@media only screen and (max-width: 992px) {
    .leftBar-heading {
        font-size: 0.7rem !important;
    }

    .leftbar-container {
        width: 30%;
        border-radius: 0;
    }
}

@media only screen and (max-width: 766px) {
    .leftBar-heading {
        font-size: 0.9rem !important;
    }

    .leftbar-container {
        width: 100%;
        height: -webkit-fill-available;
        border-radius: 0;
        position: absolute !important;
        z-index: 99 !important;
    }
}
.dash-main {
    margin-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    font-family: 'Montserrat';
}

.dashTitle {
    margin: 1rem 0.5rem 1rem 0rem !important;
    font-size: 2rem !important;
    font-weight: bold !important;
}

.dashCard {
    /* width: 100%; */
    height: 100%;
    /* height: 17rem; */
    border: 1px solid #F9FBFB;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    margin: 0 0.5rem !important;
    font-size: 0.9rem !important;
    padding: 0.9rem !important;
}

.dashCardNumber {
    position: absolute !important;
    bottom: 0 !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.poFamiliStep {
    background-color: #EBEBEB;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.poFamiliStepSuccess {
    background-color: #4DDB85;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.noOrder {
    background-color: #EBEBEB;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 5rem;
}

.spotImg {
    height: 4rem;
    width: 100%;
    object-fit: contain;
}

.recTranCard {
    background-color: #f1f1f1;
    width: 100%;
    border-radius: 15px;
    height: 100%;
    /* height: 3.3rem; */
    margin-top: 0.26rem;
    padding-bottom: 0.2rem !important
}

.refCard {
    background: linear-gradient(179.74deg, rgba(255, 255, 255, 0.3) 3.23%, rgba(243, 112, 230, 0.5) 99.77%);
    /* height: 10.5rem; */
    border-radius: 15px;
}

.chevronCircle {
    color: #b4b5b6;
}

.popComp {
    border-bottom: 2px solid #EBEBEB;
    margin-top: 0.7rem !important;
}

.refDone {
    font-size: 4.5rem;
    font-weight: bold;
}

.refDesc {
    top: -20px;
    position: relative;
}

.rewaEarn {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: right !important;
    position: relative;
    top: 5px;
}

.rewaDesc {
    top: -3px;
    position: relative;
    text-align: right;
}

.textWrap {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashCompLogo {
    width: 100%;
    height: 1rem;
    object-fit: contain;
}

.sharesCompLogo {
    width: 100%;
    height: 3rem;
    object-fit: contain;
}

.pieChartTooltipMain {
    background-color: #fff;
    border: 0.5px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 60vw;
    width: -webkit-max-content;
    width: max-content;
    padding-right: 0.5rem;
    white-space: normal;
    position: relative;
    left: 0px;
}

.pieChartTooltipColorBlock {
    min-width: 1rem;
    min-height: 1rem;
    margin: 0.5rem;
    border-radius: 50%;
}

.recentActivity {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem !important;
}


.dash-main::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.dash-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dash-main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.videoSliderBtn {
    position: absolute;
    z-index: 2;
    left: 0%;
    top: 50%;
    margin-left: 1rem;
    background-color: #89cdef;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
}

.videoSliderBtnRt {
    position: absolute;
    z-index: 2;
    right: 0%;
    top: 50%;
    margin-right: 1rem;
    background-color: #89cdef;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
}

.videoDisplay {
    width: 100%;
    border: none;
}

.stepPoint {
    background-color: #EBEBEB;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.stepPointActive {
    background-color: #93cae2 !important;
    color: #fff !important;
}

.stepPointDone {
    background-color: #52a952 !important;
    color: #fff !important;
}

.stepPointParent {
    padding: 0 1rem !important;
}

.stepPointLine {
    border-top: 3px solid #EBEBEB;
    width: 100%;
}

.stepPointLineActive {
    border-top: 3px solid #52a952 !important;
}

.stepData {
    padding: 1rem 2rem 1rem 0.5rem;
    font-size: 0.8rem
}

.highLightCard {
    background-color: #dceff8;
}

@media only screen and (max-width: 992px) {
    .dashTitle {
        margin: 1rem 0.5rem 1rem 0rem !important;
        font-size: 1.5rem !important;
    }

    .dashCard {
        font-size: 0.7rem !important;
        padding: 0.7rem !important;
    }

    .dashCardNumber {
        font-size: 0.9rem !important;
    }

    .stepPointParent {
        display: flex;
        margin-left: 0.5rem !important;
    }

    .stepPointLine {
        border-top: 0;
        width: 0;
        height: 7rem;
        border-left: 3px solid #EBEBEB;
    }

    .stepPointLineActive {
        border-left: 3px solid #52a952 !important;
    }
}

@media only screen and (max-width: 766px) {
    .dash-main {
        width: 100%;
    }

    .margin {
        margin-top: 1rem !important;
    }

    .dashTitle {
        margin: 1rem 0.5rem 1rem 0rem !important;
    }

    .refDone {
        font-size: 4rem;
    }

    .rewaEarn {
        font-size: 1rem;
    }
}
#analytics .table th {
    padding: 0.25rem 0.5rem !important;
    background-color: #F7F7F7;
    border: 0;
    font-size: 0.7rem !important;
    vertical-align: middle !important;
}

#analytics .table td {
    padding: 1rem 0.5rem !important;
    font-size: 0.7rem !important;
    vertical-align: middle !important;
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
}

.rightBorder {
    border-right: solid #F7F7F7 !important;
}

#analytics .dashCard {
    margin: 0 !important;
}

.tableBreakWord {
    white-space: normal;
    word-break: break-all;
    display: block;
}

.orderDetailColWidth {
    width: 40% !important;
}

.clientRemarkWidth {
    width: 30% !important;
}

.emptyColWidth {
    width: 50% !important;
}

.sort-arr {
    color: #aeaeae !important;
}

.sort-select-arr {
    color: #2d6aa0 !important;
}

.analyitcsSelector {
    margin: 1rem 2rem 1rem 0 !important;
    color: #11263C !important;
}

.analyitcsSelector-active {
    margin: 1rem 2rem 1rem 0 !important;
    color: #009fe3 !important;
}

.buyButton {
    background-color: rgba(25, 210, 31, 0.3);
    color: #19D21F;
    border-radius: 5px;
    width: -webkit-max-content !important;
    width: max-content !important;
    padding: 0.25rem 0.5rem !important;
}

.csvDownBtn {
    background-color: #009FE3;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-family: 'Montserrat', sans-serif !important;
}

.orderCancelBtn {
    background-color: #f00;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-family: 'Montserrat', sans-serif !important;
}

.refDataRefActive {
    border: 1px solid #98D4A9;
    color: #98D4A9;
    width: -webkit-max-content;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.refDataRefPending {
    border: 1px solid #C6892D;
    color: #C6892D;
    width: -webkit-max-content;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.commissionCardNumber {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.commissionCardIcon {
    width: 4rem !important;
    object-fit: contain !important;
}

@media only screen and (max-width: 992px) {
    .commissionCardIcon {
        width: 2.5rem !important;
        object-fit: contain !important;
    }

    .commissionCardNumber {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 766px) {
    .analyitcsSelector {
        margin: 1rem 0.5rem 1rem 0 !important;
    }

    .analyitcsSelector-active {
        margin: 1rem 0.5rem 1rem 0 !important;
    }

    .orderDetailCard {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        padding: 0.5rem !important;
        border-radius: 5px !important;
        margin: 1rem 0 !important;
        padding: 1rem !important;
        word-wrap: break-word !important;
    }

    .commissionCardNumber {
        font-size: 0.9rem !important;
    }

    .commissionCardIcon {
        width: 2.5rem !important;
        object-fit: contain !important;
    }
}
.refMain {
    margin: 2rem;
}

.referralBanner {
    width: 100%;
    border-radius: 15px;
    background: linear-gradient(180deg, #C2EDFF 0%, rgba(210, 240, 253, 0) 134.22%);
    padding: 1rem 3rem !important;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.2rem !important;
}

.referralBannerHead {
    font-weight: 800;
    font-family: 'Open Sans';
    font-size: 3rem !important;
    color: #005EB5 !important;
}

.refImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.refShare {
    text-align: center;
    background-color: #f7f7f7;
    margin: 2rem;
    padding: 1rem;
    font-size: 1rem;
}

.refShareIcon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
    object-fit: contain;
}

.refHr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgb(0 0 0);
}

.refCode {
    color: #AEAEAE !important;
    line-height: 1.2;
    text-align: right;
}

.refData {
    margin-top: 3rem !important;
}

.refBalance {
    margin-top: 2rem !important;
}

.refChange {
    background-color: #d1cece;
    border-radius: 30px;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
}

.refActive {
    background-color: #009FE3;
    color: #fff;
    margin: 0.3rem !important;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.2rem 1rem !important;
    max-width: -webkit-max-content;
    max-width: max-content;
}

.refInActive {
    height: 2rem !important;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.2rem 1rem !important;
    max-width: -webkit-max-content;
    max-width: max-content;
}

.refTableParent {
    width: 60% !important;
}

.refDataRefName {
    width: 25% !important;
}

.refDataRefActive {
    border: 1px solid #98D4A9;
    color: #98D4A9;
    width: -webkit-max-content;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.refDataRefPending {
    border: 1px solid #C6892D;
    color: #C6892D;
    width: -webkit-max-content;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

@media only screen and (max-width: 992px) {
    .refMain {
        margin: 1rem;
    }

    .referralBanner {
        padding: 0.5rem 1rem !important;
        font-size: 0.9rem !important;
    }

    .referralBannerHead {
        font-size: 1.8rem !important;
    }

    .refShare {
        margin: 2rem 0.5rem 1rem 0.5rem;
    }

    .refData {
        margin-top: 2rem !important;
    }

    .refTableParent {
        width: 75% !important;
    }
}

@media only screen and (max-width: 768px) {
    .referralBanner {
        padding: 0 !important;
        font-size: 1rem !important;
    }

    .referralBannerHead {
        padding-top: 1rem !important;
    }

    .referralBannerSubHead {
        padding-right: 2rem !important;
    }

    .refShare {
        font-size: 0.5rem;
        padding: 1rem 0.5rem;
    }

    .refShareIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .refCode {
        text-align: left;
        margin: 1rem 0 !important;
    }

    .refBalance {
        margin-top: 1rem !important;
    }

    .refData {
        margin-top: 1rem !important;
    }

    .refActive {
        font-size: 0.6rem !important;
        padding: 0.3rem 1rem !important;
    }

    .refInActive {
        font-size: 0.6rem !important;
    }

    .refInActive {
        padding: 0 0.5rem !important;
    }

    .refTableParent {
        width: 100% !important;
    }

    .refDataRefName {
        width: 50% !important;
    }
}
.assetHeader {
    margin: 0 5rem 3rem !important;
}

.assetHeaderImg {
    width: 35% !important;
    object-fit: contain !important;
}

.assetMain {
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
}

.assetCard {
    background: var(--white);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 0 1.5rem !important;
    height: 100%;
    text-align: center !important;
}

.assetCardTopDiv {
    padding: 1rem 0.5rem !important;
    border-bottom: 2px solid var(--shade-cdcbcb);
    min-height: 12rem !important
}

.assetCardHR {
    border: none;
    border-left: 2px solid var(--shade-cdcbcb) !important;
    width: 1px;
}

.assetCardBottom {
    min-height: 5rem !important;
}

.assetCardBottomNumber {
    font-weight: 500 !important;
    word-break: break-all;
}

.assetCardBottomLeft {
    padding: 1rem 0.5rem 0 !important;
    text-align: left !important;
    border-right: 1px solid var(--shade-cdcbcb) !important;
}

.assetCardBottomRight {
    padding: 1rem 1rem 0 !important;
    text-align: left !important;
    border-left: 1px solid var(--shade-cdcbcb) !important;
}

.assetCardImg {
    width: 4rem;
    object-fit: contain !important;
    margin: 0.5rem !important;
}

.assetCardOppBtnDiv {
    text-align: center !important;
    display: flex;
    justify-content: center;
    padding: 1rem 0 !important;
}

#assetOpp {
    margin-bottom: 0 !important;
    padding-bottom: 2rem !important;
}

#assetOpp .assetCardOppBtnDiv {
    justify-content: left !important;
    padding: 0 !important;
}

#assetOpp .assetCardTopDiv {
    min-height: 10rem !important;
}

#assetOpp .refData {
    margin-top: 1rem !important;
}

.assetCardOppBtn {
    padding: 0.5rem 2.5rem !important;
    background: var(--primary);
    color: var(--white);
    box-shadow: 0px 2.63736px 2.63736px rgb(0 0 0 / 24%);
    border-radius: 5px;
    width: -webkit-max-content;
    width: max-content;
}

.assetOppBgImg {
    width: 100%;
    position: fixed;
    top: 35%;
    z-index: -1;
}

.assetOppImg {
    width: 100% !important;
    object-fit: contain !important;
    height: 4rem !important;
}

#assetDetail .assetOppImg {
    height: 8rem !important;
}

#assetOpp .assetCardBottom {
    padding: 1rem 0.5rem !important;
    text-align: left !important;
}

.assetOppSector {
    padding: 1rem 0 !important;
}

.assetOppSectorValue {
    padding: 0 1rem !important;
    background: var(--primary);
    color: var(--white);
    border-radius: 25px;
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
    margin-top: 0.5rem !important;
    word-break: break-word;
}

#faq {
    margin-top: 2rem !important;
    border-top: 2px solid var(--primary);
    padding: 1rem 0 3rem !important;
    margin-bottom: 0 !important;
}

.assetDetailMain {
    background-color: var(--shade-EBEBEB);
}

.assetBgImage {
    background-image: url(/static/media/assetBg.e8975444.svg);
    background-size: cover;
    background-position: 0 8rem;
    background-repeat: no-repeat;
    min-height: 100%;
}

.assetDetailBg {
    border-radius: 15px !important;
    background-color: var(--white);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.assetDetailHeader {
    background-color: var(--line-border-empty);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1rem 2rem !important;
}

.assetDetailHeaderSub {
    color: var(--shade-AEAEAE);
    padding-bottom: 1rem !important;
}

.assetDetailTab {
    background-color: var(--shade-cdcbcb);
    padding-top: 1.5rem !important;
    display: flex;
    justify-content: space-around;
}

.assetDetailTabSelected {
    border-bottom: 3px solid var(--primary);
    padding-bottom: 0.5rem !important;
}

.assetDetailPriceCard {
    padding: 2rem 1rem !important;
}

.assetDetailPriceCardTitle {
    font-weight: 500 !important;
}

.assetDetailInvestBtn {
    margin-top: 1.5rem !important;
    padding: 0.5rem 0 !important;
    text-align: center !important;
    background: var(--primary);
    color: var(--white);
    box-shadow: 0px 2.63736px 2.63736px rgb(0 0 0 / 24%);
    border-radius: 5px;
}

.videoDisplayParent {
    width: 100%;
    max-height: 50%;
    margin-bottom: 1rem;
    padding-top: 56.25%;
    position: relative
}

.videoDisplay {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#fttSectionData img {
    width: 100% !important;
    height: auto !important;
}

#fttSectionData iframe {
    width: 100% !important;
    height: auto !important;
}

.paymentLink {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

@media only screen and (max-width: 992px) and (min-width: 769px) {
    .assetHeader {
        margin: 0 2rem 3rem !important;
    }

    .assetBgImage {
        height: 100% !important;
    }

    .assetHeaderImg {
        object-fit: contain !important;
    }

    .assetCard {
        margin: 0 1rem !important;
    }

    .assetCardTopDiv {
        padding: 1rem 0.5rem !important;
    }

    .assetCardBottom {
        min-height: 7rem !important;
    }

    .assetCardBottomRight {
        padding: 1rem 0.5rem !important;
    }

    .assetCardBottomLeft {
        padding: 1rem 0.5rem !important;
    }

    .assetOppBgImg {
        top: 15%;
    }
}

@media only screen and (max-width: 768px) {

    .assetMain {
        width: 100% !important;
    }

    .assetBgImage {
        background: none !important;
    }

    .assetHeader {
        margin: 0 0 1rem !important;
    }

    .assetHeaderImg {
        object-fit: contain !important;
        margin-top: 1rem !important;
    }

    .assetCard {
        margin: 0 !important;
        text-align: left !important;
    }

    .assetCardTopDiv {
        padding: 1rem 0.5rem !important;
        min-height: 0 !important;
        height: auto !important;
    }

    .assetCardBottom {
        min-height: 0 !important;
        height: auto !important;
    }

    .assetCardBottomRight {
        padding: 1rem 1rem !important;
    }

    .assetCardBottomLeft {
        padding: 1rem 1rem !important;
    }

    .assetOppBgImg {
        display: none;
    }

    #assetDetail .assetOppImg {
        height: 4rem !important;
    }

    #faq {
        padding-bottom: 1rem !important;
    }

    .assetDetailHeader {
        padding: 1rem 1rem !important;
    }

    .assetDetailTab {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        gap: 10px;
        padding-bottom: 1rem !important;
    }

    .assetDetailTabSelected {
        width: -webkit-max-content;
        width: max-content;
    }

    .tabWidth {
        margin: 0 1rem;
        width: -webkit-max-content;
        width: max-content;
    }
}


#faq .MuiAccordion-root {
    margin-bottom: 0.5rem !important;
}

#faq .MuiAccordionDetails-root {
    padding-left: 8px !important;
}

#faq .MuiAccordionSummary-root {
    padding-left: 0 !important;
}

#faq .MuiAccordionSummary-content {
    margin: 0 !important;
}

#faq .MuiAccordionSummary-content .MuiTypography-body1 {
    font-weight: bold !important;
}

#faq .MuiAccordionSummary-content.Mui-expanded {
    padding-top: 16px !important;
}

#faq .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}

#faq .MuiPaper-elevation1 {
    box-shadow: none !important;
}

#faq .MuiPaper-root {
    background-color: unset !important;
}

#faq .MuiAccordion-root:before {
    background-color: unset !important;
}

.questionMark {
    margin: 0 0.3rem !important;
    background-color: var(--primary);
    color: var(--white);
    padding: 0.1rem 0.2rem 0.15rem;
    border-radius: 30%;
}

.custProfileUl {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.custProfileUl a {
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    margin: 0 0.5rem;
    white-space: nowrap;
    color: black !important;
}

.custProfileUlActive {
    border-bottom: 4px solid var(--primary);
}


.wrapper {
    padding: 2rem 2rem 0;
    background: var(--white);
    display: flex;
    flex-direction: column;
}

.wrapper-cell {
    display: flex;
    margin-bottom: 30px;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.animated-background, .text-line, .image {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.image {
    height: 60px;
    width: 60px;
}

.text {
    margin-left: 20px;
}

.text-line {
    height: 10px;
    width: 60vw;
    margin: 4px 0;
}
.shares-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    /* margin-top: 57px; */
}

.shares-header {
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 10px 20px 0px 20px;
}

.demat-logo {
    height: 30px;
}

.shares-detail {
    overflow: auto;
    width: 70%;
    padding-right: 15px;
    margin-right: 10px;
}

.shares-detail-container {
    margin: 20px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-y: auto;
}

.shares-buy {
    overflow-y: auto;
    overflow-x: clip;
    width: 30%;
    border: 1px solid var(--line-border-empty);
    border-radius: 10px;
    padding: 5px 10px 0px 10px;
}

.shares-create-order {
    margin: 10px 20px 5px 20px;
    overflow: auto;
    display: flex;
}

.shares-create-header {
    margin: 10px 10px 10px 20px;
}

@media only screen and (max-width: 766px) {
    .shares-container {
        width: 100%;
    }

    .shares-header {
        width: 90%;
    }

    .demat-logo {
        height: 20px;
    }

    .shares-detail {
        width: 100%;
        margin-right: 0px;
    }

    .shares-detail-container {
        margin-top: 0px;
        margin-right: 10px;
    }

    .shares-buy {
        display: none;
        width: 100%;
        margin-top: 1.5rem !important;
    }

    .shares-create-order {
        display: block;
    }
}

/* custom scroll bar */

.shares-detail::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-detail::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-detail::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.shares-buy::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-buy::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-buy::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.shares-create-order::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-create-order::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-create-order::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.card-body::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.card-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.press-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--white);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    margin-left: 20px;
    margin-bottom: 20px;
}

/* shares top bar list css */

.buycheck {
    background-color: rgba(25, 210, 31, 0.3);
    padding: 5px 10px;
    border-radius: 3px;
    padding-bottom: 7px;
    color: #19d21f;
    margin: 0 10px;
}

.sellcheck {
    background-color: rgba(210, 25, 25, 0.3);
    padding: 5px 10px;
    border-radius: 3px;
    padding-bottom: 7px;
    color: #d26c19;
    margin: 0 10px;
}

.custom-card {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
    padding: 15px 10px;
}

.custom-link:hover {
    text-decoration: none;
}

.sub-details {
    color: #a8a8a8;
    margin-right: 5px;
}

.utr-card {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
    padding: 7px;
}

.info-card {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.info-card p {
    margin-bottom: 0;
}

.buy-card {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--line-border-empty);
    padding: 0.25rem 0 !important;
}

.buy-card:hover {
    border: 1px solid var(--primary);
}

.custom-button {
    border: none;
    background: none;
}

.custom-button:hover {
    cursor: pointer;
}

.variable-card {
    border-left: 3px solid var(--primary);
    padding: 5px;
    margin-top: 10px;
    margin-right: 5px;
    box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
}

.btn-blue {
    background-color: rgba(183, 208, 230, 0.5) !important;
    color: var(--primary) !important;
    padding: 8px 10px !important;
    background-color: var(--primary);
    cursor: pointer;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
}

.btn-blue-danger {
    background-color: rgba(255, 0, 0, 0.2) !important;
    color: #f00 !important;
    padding: 8px 10px !important;
    background-color: var(--primary);
    cursor: pointer;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
}

.menu-list {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}

.menu-list::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.style-menu {
    margin: 0;
    padding: 0;
    width: 100%;
    background: var(--white);
    white-space: nowrap
}

.style-menu-item {
    display: inline-block;
}

.active {
    color: var(--primary);
    border-bottom: 4px solid var(--primary);
}

.style-menu-item a {
    display: block;
    color: #5a5858;
    text-align: center;
    margin-right: 30px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 500;
}

.style-menu-item a:hover {
    color: var(--primary);
    text-decoration: none;
}

.company-chart {
    margin-top: 30px;
    width: 100%;
}

.company-chart-range-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.company-chart-range-btn {
    border-radius: 6px;
    border: none;
}

/* buy sell button on buy panel */

.btn-buy {
    color: #19d21f;
    /* border: ; */
}

.btn-sell {
    color: red;
}

.active>.btn-buy {
    color: white;
    background-color: #19d21f;
}

.active>.btn-sell {
    color: white;
    background-color: #ff3a3a;
}

.btn-buysell-nav {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
}

/* --------------------------------------screener---------------------------------------- */
.sort-arr {
    color: var(--shade-AEAEAE) !important;
}

.sort-select-arr {
    color: var(--primary) !important;
}
