html {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Montserrat', sans-serif, Poppins, 'Open Sans', 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace, Poppins, 'Open Sans', 'Montserrat', sans-serif;
}

.table-scroll {
  width: 100%;
  overflow: auto;
}

:root {
  --primary: #2d6aa0;
  height: 100% !important;
  --blue: #009FE3;
  --golden: rgb(232, 168, 56);
  --primary: var(--blue);
  --line-border-empty: #e1e1e1;
  --success: #28a745;
  --white: #FFFFFF;
  --black: #000000;
  --shade-AEAEAE: #AEAEAE;
  --shade-EBEBEB: #EBEBEB;
  --shade-cdcbcb: #cdcbcb;
  --shade-c7c7c7: #c7c7c7;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.btn-primary {
  background-color: var(--primary) !important;
}

.btn-danger {
  background-color: #f00 !important;
}

.btn-custom {
  padding: 10px 20px;
}

.text-custom-grey {
  color: #A8A8A8;
  font-weight: 500;
}

.text-custom-greyDark {
  color: #5A5858;
  font-weight: 500;
}

.text-custom-greyLight {
  color: #E1E1E1;
  font-weight: 500;
}

.break-text {
  word-wrap: break-word;
  /* word-break: break-all; */
}

/* gloabl font size */
.f-32 {
  font-size: 2.5rem !important;
}

.f-18 {
  font-size: 1.2rem !important;
}

.f-16 {
  font-size: 1rem !important;
}

.f-14 {
  font-size: 0.9rem !important;
}

.f-12 {
  font-size: 0.7rem !important;
}

.f-10 {
  font-size: 0.6rem !important;
}

.f-9 {
  font-size: 0.5rem !important;
}

.curPoint {
  cursor: pointer;
}

.noBorder {
  border: none !important;
}

.table-scroll {
  width: 100%;
  overflow: auto;
}

.initial-div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.initial-contain {
  height: 100%;
  overflow-y: auto;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* margin-top: 3.7rem !important; */
}

.select-ht {
  height: fit-content !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 766px) {
  .initial-contain {
    overflow-y: auto;
  }
}

/* ------------------------loader----------------------------------- */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 12px !important;
}

.MuiInputBase-input {
  margin-top: 0px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiIconButton-root {
  padding: unset !important;
}

.MuiTableCell-root {
  font-size: unset !important;
}

.MTableHeader-header-6 {
  font-size: 0.8rem !important;
}

.MuiAccordionDetails-root {
  display: block !important;
}

#onesignal-bell-container {
  bottom: 30px !important;
  right: -15px !important;
}

.modalPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.f-12 {
  font-size: 0.7rem !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* sample font size */

.defaultFont {
  font-size: 1rem !important;
}

.heading-spec {
  font-size: 3.5rem;
}

.heading1 {
  font-size: 3rem;
}

.subheading1 {
  font-size: 1rem;
}

.heading2 {
  font-size: 2.5rem;
}

.subheading2 {
  font-size: 1.2rem;
}

.heading3 {
  font-size: 2rem;
}

.heading4 {
  font-size: 1.5rem;
}

.subContent {
  font-size: 0.8rem;
}

.helpBtn {
  font-size: 1.5rem;
}

@media only screen and (max-width: 300px) {
  .heading1 {
    font-size: 1.5rem !important;
  }

  .subheading1 {
    font-size: 1rem !important;
  }

  .heading2 {
    font-size: 1.5rem !important;
  }

  .heading3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .heading1 {
    font-size: 2rem !important;
  }

  .heading4 {
    font-size: 1.3rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .subheading1 {
    font-size: 1.5rem !important;
  }

  .heading2 {
    font-size: 3rem !important;
  }
}

/* sample font size */