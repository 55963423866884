#test {
  --marquee-width: 100vw;
  /* --marquee-elements: 12; */
  /* defined with JavaScript */
  --marquee-elements-displayed: 6;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  color: #111;
  overflow: hidden;
  position: relative;
}

.marquee:before, .marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  /* background: linear-gradient(to right, #fff 0%, transparent 100%); */
}

.marquee:after {
  right: 0;
  /* background: linear-gradient(to left, #fff 0%, transparent 100%); */
}

.marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
  margin-bottom: 0;
}

/* .marquee-content:hover {
    animation-play-state: paused;
  } */
@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
  }
}

.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  /* 5rem; */
  white-space: nowrap;
}


.animCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #AEAEAE;
  font-weight: bold;
}

.animImgCard {
  width: 6rem;
  height: 6rem;
  border: 2px solid #EBEBEB;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: #fff;

}

.animImg {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

@media (max-width: 992px) {

  #test {
    --marquee-width: 100vw;
    --marquee-elements-displayed: 4;
  }

  .marquee:before, .marquee:after {
    width: 5rem;
  }
}

@media (max-width: 768px) {

  #test {
    --marquee-width: 98vw;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0.05rem;
    margin-right: 0.05rem;
    --marquee-elements-displayed: 3;
  }

  .marquee:before, .marquee:after {
    width: 5rem;
  }

  .animCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #AEAEAE;
    font-weight: bold;
  }

  .animImgCard {
    width: 4rem;
    height: 4rem;
    border: 2px solid #EBEBEB;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    background-color: #fff;

  }
}


/* sign in animation--------------------------------------------------------------------- */

.gSignIn-parent {
  height: 2.5rem !important;
  /* background-color: #009FE3; */
  color: #fff;
  display: flex;
  justify-content: center;
}

.gSignIn-Anim {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #009FE3;
  color: #009FE3;
  box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  50% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px -10px 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px -10px 0 0 #009FE3;
  }

  100% {
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
  }
}