.navbar-container {
    height: 60px;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px !important;
}

.navbar-company-logo-container {
    width: 80px;
}

.navbar-items-container>* {
    margin-left: 40px;
}

.navbar-items-user {
    width: 40px;
}

.navbar-items-link * {
    color: #5a5858;
    font-weight: 500;
}

.navbar-items-link:hover a {
    text-decoration: none !important;
}

/* mobile nav */

.navbar-mobile-items-container>* {
    margin-left: 20px;
}

.navbar-mobile-items-link * {
    color: #2d6aa0;
    font-size: 20px;
    font-weight: 500;
}

.navbar-mobile-items-link:hover a {
    text-decoration: none !important;
}

/* PROFILE MODAL */

.navbar-profile-modal-container {
    position: absolute;
    top: 63px;
    right: 10px;
    width: 280px;
    background-color: red;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    font-size: 12px;
    z-index: 99999;
}

#topbar {
    z-index: 999 !important;
    height: 65px;
}

@media screen and (max-width: 576px) {
    .navbar-profile-modal-container {
        width: calc(100% - 20px);
    }

    #topbar {
        z-index: 999 !important;
        height: 70px;
    }
}

/* NOTIFICATION MODAL */

.notify-modal-container {
    position: absolute;
    top: 63px;
    right: 10px;
    width: 400px;
    font-size: 12px;
    z-index: 99999;
}

@media screen and (max-width: 576px) {
    .notify-modal-container {
        width: calc(100% - 20px);
    }
}

/* notification bell */

.noti-not-read-icon {
    position: absolute;
    background-color: red;
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

.noti-bell-main {
    display: flex;
    align-items: baseline;
    justify-content: end;
}

.unread-not-bg {
    background-color: #e6e6e6 !important;
}

.notiParent {
    padding: 2px;
    background: white;
    border-radius: 7px;
}

/* buy sell bottom bar mobile button */

.buy-sell-mob-btn {
    position: fixed !important;
    bottom: 50px !important;
    width: 100% !important;
}

.btm-bar {
    height: 60px;
}

.btm-bar-hr {
    height: 2rem;
    border: none;
    border-left: 1px solid #dee2e6 !important;
    width: 1px;
}

.btm-bar-space {
    justify-content: space-evenly !important;
}

.screener-btmbtn-container {
    position: fixed !important;
    bottom: 60px !important;
}

/* social media help icon */
.socialmedia-help {
    font-size: 25px;
    z-index: 23432423;
    position: fixed;
    right: 0;
    bottom: 20%;
    color: #2d6aa0;
    background-color: #e6e6e6;
    height: 120px;
    width: 30px;
    text-align: center;
    border-radius: 3px;
}

@media screen and (max-width: 576px) {
    .notify-modal-container {
        width: calc(100% - 20px);
    }

    .socialmedia-help {
        font-size: 25px;
        height: 120px;
        width: 30px;
    }
}

/* custom scroll bar */

.notificationPanel::-webkit-scrollbar {
    width: 0.7em;
    height: 0.2em;
}

.notificationPanel::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.notificationPanel::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* screener custom css */

.screener::-webkit-scrollbar {
    width: 0.3em;
    height: 0.2em;
}

.screener::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.screener::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.impersonating-bar {
    height: 1.2rem;
    font-size: 0.8rem;
    background-color: var(--golden);
    color: var(--white);
    text-align: center;
    max-width: 300px;
    margin: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 0 0.5rem;
}

.impersonating-bar-text {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    margin-right: 0.5rem !important;
}