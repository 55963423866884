#analytics .table th {
    padding: 0.25rem 0.5rem !important;
    background-color: #F7F7F7;
    border: 0;
    font-size: 0.7rem !important;
    vertical-align: middle !important;
}

#analytics .table td {
    padding: 1rem 0.5rem !important;
    font-size: 0.7rem !important;
    vertical-align: middle !important;
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
}

.rightBorder {
    border-right: solid #F7F7F7 !important;
}

#analytics .dashCard {
    margin: 0 !important;
}

.tableBreakWord {
    white-space: normal;
    word-break: break-all;
    display: block;
}

.orderDetailColWidth {
    width: 40% !important;
}

.clientRemarkWidth {
    width: 30% !important;
}

.emptyColWidth {
    width: 50% !important;
}

.sort-arr {
    color: #aeaeae !important;
}

.sort-select-arr {
    color: #2d6aa0 !important;
}

.analyitcsSelector {
    margin: 1rem 2rem 1rem 0 !important;
    color: #11263C !important;
}

.analyitcsSelector-active {
    margin: 1rem 2rem 1rem 0 !important;
    color: #009fe3 !important;
}

.buyButton {
    background-color: rgba(25, 210, 31, 0.3);
    color: #19D21F;
    border-radius: 5px;
    width: max-content !important;
    padding: 0.25rem 0.5rem !important;
}

.csvDownBtn {
    background-color: #009FE3;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: fit-content !important;
    font-family: 'Montserrat', sans-serif !important;
}

.orderCancelBtn {
    background-color: #f00;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: fit-content !important;
    font-family: 'Montserrat', sans-serif !important;
}

.refDataRefActive {
    border: 1px solid #98D4A9;
    color: #98D4A9;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.refDataRefPending {
    border: 1px solid #C6892D;
    color: #C6892D;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.commissionCardNumber {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.commissionCardIcon {
    width: 4rem !important;
    object-fit: contain !important;
}

@media only screen and (max-width: 992px) {
    .commissionCardIcon {
        width: 2.5rem !important;
        object-fit: contain !important;
    }

    .commissionCardNumber {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 766px) {
    .analyitcsSelector {
        margin: 1rem 0.5rem 1rem 0 !important;
    }

    .analyitcsSelector-active {
        margin: 1rem 0.5rem 1rem 0 !important;
    }

    .orderDetailCard {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        padding: 0.5rem !important;
        border-radius: 5px !important;
        margin: 1rem 0 !important;
        padding: 1rem !important;
        word-wrap: break-word !important;
    }

    .commissionCardNumber {
        font-size: 0.9rem !important;
    }

    .commissionCardIcon {
        width: 2.5rem !important;
        object-fit: contain !important;
    }
}