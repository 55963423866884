.dash-main {
    margin-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    font-family: 'Montserrat';
}

.dashTitle {
    margin: 1rem 0.5rem 1rem 0rem !important;
    font-size: 2rem !important;
    font-weight: bold !important;
}

.dashCard {
    /* width: 100%; */
    height: 100%;
    /* height: 17rem; */
    border: 1px solid #F9FBFB;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    margin: 0 0.5rem !important;
    font-size: 0.9rem !important;
    padding: 0.9rem !important;
}

.dashCardNumber {
    position: absolute !important;
    bottom: 0 !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.poFamiliStep {
    background-color: #EBEBEB;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.poFamiliStepSuccess {
    background-color: #4DDB85;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.noOrder {
    background-color: #EBEBEB;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 5rem;
}

.spotImg {
    height: 4rem;
    width: 100%;
    object-fit: contain;
}

.recTranCard {
    background-color: #f1f1f1;
    width: 100%;
    border-radius: 15px;
    height: 100%;
    /* height: 3.3rem; */
    margin-top: 0.26rem;
    padding-bottom: 0.2rem !important
}

.refCard {
    background: linear-gradient(179.74deg, rgba(255, 255, 255, 0.3) 3.23%, rgba(243, 112, 230, 0.5) 99.77%);
    /* height: 10.5rem; */
    border-radius: 15px;
}

.chevronCircle {
    color: #b4b5b6;
}

.popComp {
    border-bottom: 2px solid #EBEBEB;
    margin-top: 0.7rem !important;
}

.refDone {
    font-size: 4.5rem;
    font-weight: bold;
}

.refDesc {
    top: -20px;
    position: relative;
}

.rewaEarn {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: right !important;
    position: relative;
    top: 5px;
}

.rewaDesc {
    top: -3px;
    position: relative;
    text-align: right;
}

.textWrap {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashCompLogo {
    width: 100%;
    height: 1rem;
    object-fit: contain;
}

.sharesCompLogo {
    width: 100%;
    height: 3rem;
    object-fit: contain;
}

.pieChartTooltipMain {
    background-color: #fff;
    border: 0.5px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 60vw;
    width: max-content;
    padding-right: 0.5rem;
    white-space: normal;
    position: relative;
    left: 0px;
}

.pieChartTooltipColorBlock {
    min-width: 1rem;
    min-height: 1rem;
    margin: 0.5rem;
    border-radius: 50%;
}

.recentActivity {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem !important;
}


.dash-main::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.dash-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dash-main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.videoSliderBtn {
    position: absolute;
    z-index: 2;
    left: 0%;
    top: 50%;
    margin-left: 1rem;
    background-color: #89cdef;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
}

.videoSliderBtnRt {
    position: absolute;
    z-index: 2;
    right: 0%;
    top: 50%;
    margin-right: 1rem;
    background-color: #89cdef;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
}

.videoDisplay {
    width: 100%;
    border: none;
}

.stepPoint {
    background-color: #EBEBEB;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.stepPointActive {
    background-color: #93cae2 !important;
    color: #fff !important;
}

.stepPointDone {
    background-color: #52a952 !important;
    color: #fff !important;
}

.stepPointParent {
    padding: 0 1rem !important;
}

.stepPointLine {
    border-top: 3px solid #EBEBEB;
    width: 100%;
}

.stepPointLineActive {
    border-top: 3px solid #52a952 !important;
}

.stepData {
    padding: 1rem 2rem 1rem 0.5rem;
    font-size: 0.8rem
}

.highLightCard {
    background-color: #dceff8;
}

@media only screen and (max-width: 992px) {
    .dashTitle {
        margin: 1rem 0.5rem 1rem 0rem !important;
        font-size: 1.5rem !important;
    }

    .dashCard {
        font-size: 0.7rem !important;
        padding: 0.7rem !important;
    }

    .dashCardNumber {
        font-size: 0.9rem !important;
    }

    .stepPointParent {
        display: flex;
        margin-left: 0.5rem !important;
    }

    .stepPointLine {
        border-top: 0;
        width: 0;
        height: 7rem;
        border-left: 3px solid #EBEBEB;
    }

    .stepPointLineActive {
        border-left: 3px solid #52a952 !important;
    }
}

@media only screen and (max-width: 766px) {
    .dash-main {
        width: 100%;
    }

    .margin {
        margin-top: 1rem !important;
    }

    .dashTitle {
        margin: 1rem 0.5rem 1rem 0rem !important;
    }

    .refDone {
        font-size: 4rem;
    }

    .rewaEarn {
        font-size: 1rem;
    }
}