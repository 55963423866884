.refMain {
    margin: 2rem;
}

.referralBanner {
    width: 100%;
    border-radius: 15px;
    background: linear-gradient(180deg, #C2EDFF 0%, rgba(210, 240, 253, 0) 134.22%);
    padding: 1rem 3rem !important;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.2rem !important;
}

.referralBannerHead {
    font-weight: 800;
    font-family: 'Open Sans';
    font-size: 3rem !important;
    color: #005EB5 !important;
}

.refImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.refShare {
    text-align: center;
    background-color: #f7f7f7;
    margin: 2rem;
    padding: 1rem;
    font-size: 1rem;
}

.refShareIcon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
    object-fit: contain;
}

.refHr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgb(0 0 0);
}

.refCode {
    color: #AEAEAE !important;
    line-height: 1.2;
    text-align: right;
}

.refData {
    margin-top: 3rem !important;
}

.refBalance {
    margin-top: 2rem !important;
}

.refChange {
    background-color: #d1cece;
    border-radius: 30px;
    display: flex;
    align-items: center;
    width: max-content;
}

.refActive {
    background-color: #009FE3;
    color: #fff;
    margin: 0.3rem !important;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.2rem 1rem !important;
    max-width: max-content;
}

.refInActive {
    height: 2rem !important;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.2rem 1rem !important;
    max-width: max-content;
}

.refTableParent {
    width: 60% !important;
}

.refDataRefName {
    width: 25% !important;
}

.refDataRefActive {
    border: 1px solid #98D4A9;
    color: #98D4A9;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

.refDataRefPending {
    border: 1px solid #C6892D;
    color: #C6892D;
    width: max-content;
    font-weight: bold;
    padding: 0.2rem 0.5rem;
}

@media only screen and (max-width: 992px) {
    .refMain {
        margin: 1rem;
    }

    .referralBanner {
        padding: 0.5rem 1rem !important;
        font-size: 0.9rem !important;
    }

    .referralBannerHead {
        font-size: 1.8rem !important;
    }

    .refShare {
        margin: 2rem 0.5rem 1rem 0.5rem;
    }

    .refData {
        margin-top: 2rem !important;
    }

    .refTableParent {
        width: 75% !important;
    }
}

@media only screen and (max-width: 768px) {
    .referralBanner {
        padding: 0 !important;
        font-size: 1rem !important;
    }

    .referralBannerHead {
        padding-top: 1rem !important;
    }

    .referralBannerSubHead {
        padding-right: 2rem !important;
    }

    .refShare {
        font-size: 0.5rem;
        padding: 1rem 0.5rem;
    }

    .refShareIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .refCode {
        text-align: left;
        margin: 1rem 0 !important;
    }

    .refBalance {
        margin-top: 1rem !important;
    }

    .refData {
        margin-top: 1rem !important;
    }

    .refActive {
        font-size: 0.6rem !important;
        padding: 0.3rem 1rem !important;
    }

    .refInActive {
        font-size: 0.6rem !important;
    }

    .refInActive {
        padding: 0 0.5rem !important;
    }

    .refTableParent {
        width: 100% !important;
    }

    .refDataRefName {
        width: 50% !important;
    }
}