/* Left Pane Container */

.leftbar-container {
    /* margin-top: 57px; */
    width: 20%;
    /* height: fit-content; */
    flex-direction: column;
    overflow-y: auto;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 1rem;
    background-color: #fff !important;
}

.leftBar-cont {
    border: 1px solid #ebf0f0;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
}

#leftBar .dashCard {
    height: auto;
}

.leftBar-header {
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.leftBar-heading {
    font-size: 0.9rem !important;
    font-weight: bold !important;
}

.leftbar-content {
    padding: 1rem 1rem !important;
    text-align: center !important;
}

.leftBar-header-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    object-fit: contain !important;
}

.leftBar-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leftBar-icon {
    width: 2.5rem !important;
    height: 2.5rem !important;
    object-fit: contain;
}

.leftBar-icon-bg {
    padding: 0.5rem !important;
    text-align: center;
    object-fit: contain;
    background-color: #dcdbed;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem !important;
}

.leftBar-text {
    margin-top: 2rem !important;
}

.leftBar-link {
    margin-top: 0.5rem !important;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: left !important;
    padding: 0.2rem !important;
    word-break: break-all !important;
}

.leftBar-CopyLink {
    background-color: #009FE3;
    color: #fff;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: 100% !important;
    margin: 0.5rem 0 !important
}

.leftBar-CopiedLink {
    background-color: #66d367;
    color: #fff;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem !important;
    width: 100% !important;
    margin: 0.5rem 0 !important
}

.leftBar-share {
    padding-top: 1rem !important;
}

.highLight {
    box-shadow: inset 0px -2px 14px 13px rgb(32 207 226 / 46%);
}

/* COMPANY TABLE */

.side-nav-company-table {
    /* margin-top: 30px; */
    /* height: calc(100% - 40px); */
    overflow-x: auto;
    height: 100%;
    display: flex;
}

.side-nav-company-table table {
    border-collapse: unset;
    height: fit-content;
}

.side-nav-company-table tr {
    height: 2.5rem;
}

.side-nav-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.side-nav-buy-button {
    color: #28a745;
}

.side-nav-sell-button {
    color: #dc3545;
}


@media only screen and (max-width: 992px) {
    .leftBar-heading {
        font-size: 0.7rem !important;
    }

    .leftbar-container {
        width: 30%;
        border-radius: 0;
    }
}

@media only screen and (max-width: 766px) {
    .leftBar-heading {
        font-size: 0.9rem !important;
    }

    .leftbar-container {
        width: 100%;
        height: -webkit-fill-available;
        border-radius: 0;
        position: absolute !important;
        z-index: 99 !important;
    }
}