.shares-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    /* margin-top: 57px; */
}

.shares-header {
    width: 95%;
    height: fit-content;
    margin: 10px 20px 0px 20px;
}

.demat-logo {
    height: 30px;
}

.shares-detail {
    overflow: auto;
    width: 70%;
    padding-right: 15px;
    margin-right: 10px;
}

.shares-detail-container {
    margin: 20px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-y: auto;
}

.shares-buy {
    overflow-y: auto;
    overflow-x: clip;
    width: 30%;
    border: 1px solid var(--line-border-empty);
    border-radius: 10px;
    padding: 5px 10px 0px 10px;
}

.shares-create-order {
    margin: 10px 20px 5px 20px;
    overflow: auto;
    display: flex;
}

.shares-create-header {
    margin: 10px 10px 10px 20px;
}

@media only screen and (max-width: 766px) {
    .shares-container {
        width: 100%;
    }

    .shares-header {
        width: 90%;
    }

    .demat-logo {
        height: 20px;
    }

    .shares-detail {
        width: 100%;
        margin-right: 0px;
    }

    .shares-detail-container {
        margin-top: 0px;
        margin-right: 10px;
    }

    .shares-buy {
        display: none;
        width: 100%;
        margin-top: 1.5rem !important;
    }

    .shares-create-order {
        display: block;
    }
}

/* custom scroll bar */

.shares-detail::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-detail::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-detail::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.shares-buy::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-buy::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-buy::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.shares-create-order::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.shares-create-order::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.shares-create-order::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.card-body::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

.card-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.card-body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.press-card {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--white);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    margin-left: 20px;
    margin-bottom: 20px;
}

/* shares top bar list css */

.buycheck {
    background-color: rgba(25, 210, 31, 0.3);
    padding: 5px 10px;
    border-radius: 3px;
    padding-bottom: 7px;
    color: #19d21f;
    margin: 0 10px;
}

.sellcheck {
    background-color: rgba(210, 25, 25, 0.3);
    padding: 5px 10px;
    border-radius: 3px;
    padding-bottom: 7px;
    color: #d26c19;
    margin: 0 10px;
}

.custom-card {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
    padding: 15px 10px;
}

.custom-link:hover {
    text-decoration: none;
}

.sub-details {
    color: #a8a8a8;
    margin-right: 5px;
}

.utr-card {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
    padding: 7px;
}

.info-card {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.info-card p {
    margin-bottom: 0;
}

.buy-card {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--line-border-empty);
    padding: 0.25rem 0 !important;
}

.buy-card:hover {
    border: 1px solid var(--primary);
}

.custom-button {
    border: none;
    background: none;
}

.custom-button:hover {
    cursor: pointer;
}

.variable-card {
    border-left: 3px solid var(--primary);
    padding: 5px;
    margin-top: 10px;
    margin-right: 5px;
    box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
}

.btn-blue {
    background-color: rgba(183, 208, 230, 0.5) !important;
    color: var(--primary) !important;
    padding: 8px 10px !important;
    background-color: var(--primary);
    cursor: pointer;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
}

.btn-blue-danger {
    background-color: rgba(255, 0, 0, 0.2) !important;
    color: #f00 !important;
    padding: 8px 10px !important;
    background-color: var(--primary);
    cursor: pointer;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
}

.menu-list {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}

.menu-list::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.style-menu {
    margin: 0;
    padding: 0;
    width: 100%;
    background: var(--white);
    white-space: nowrap
}

.style-menu-item {
    display: inline-block;
}

.active {
    color: var(--primary);
    border-bottom: 4px solid var(--primary);
}

.style-menu-item a {
    display: block;
    color: #5a5858;
    text-align: center;
    margin-right: 30px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 500;
}

.style-menu-item a:hover {
    color: var(--primary);
    text-decoration: none;
}

.company-chart {
    margin-top: 30px;
    width: 100%;
}

.company-chart-range-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.company-chart-range-btn {
    border-radius: 6px;
    border: none;
}

/* buy sell button on buy panel */

.btn-buy {
    color: #19d21f;
    /* border: ; */
}

.btn-sell {
    color: red;
}

.active>.btn-buy {
    color: white;
    background-color: #19d21f;
}

.active>.btn-sell {
    color: white;
    background-color: #ff3a3a;
}

.btn-buysell-nav {
    border: 1px solid var(--line-border-empty);
    border-radius: 5px;
}

/* --------------------------------------screener---------------------------------------- */
.sort-arr {
    color: var(--shade-AEAEAE) !important;
}

.sort-select-arr {
    color: var(--primary) !important;
}