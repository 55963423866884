.kyc-container {
  display: flex;
  width: 80%;
  /* margin-top: 57px; */
}

.kyc-main {
  /* height: calc(100vh - 60px); */
  overflow: auto
}

.text-theme {
  color: #2d6aa0;
  font-weight: 500;
}

.form-control {
  font-size: 10px;
}

.panel-card {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hr-kyc {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.alert {
  padding: 5px;
}

.number-curve {
  background-color: #eeeeee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  text-align: center;
  margin-right: 10px;
}

.number-heading {
  width: calc(100% - 60px);
}

.head-number-active {
  font-size: 20px;
  font-weight: 700;
  color: #2d6aa0 !important;
}

.head-number-next {
  font-size: 20px;
  font-weight: 700;
  color: #5a5858 !important;
}

.head-number-prev {
  font-size: 20px;
  font-weight: 700;
  color: #999 !important;
}

.main-heading-active {
  color: #2d6aa0 !important;
  font-size: 16px;
  font-weight: 600;
}

.main-heading-prev {
  color: #999 !important;
  font-size: 16px;
  font-weight: 600;
}

.main-heading-next {
  color: #5a5858 !important;
  font-size: 16px;
  font-weight: 600;
}

.sub-heading {
  font-size: 12px;
  color: #a8a8a8;
}

.main-kycPanel {
  background-color: #eeeeee;
  padding: 10px 20px;
  overflow: auto;
  /* height: calc(100vh - 60px); */
  width: 100%
}

@media only screen and (max-width: 766px) {
  .kyc-container {
    width: 100%;
  }
  /* .main-kycPanel {
    height: calc(100vh - 110px);
  } */
}

.custom-link {
  cursor: pointer;
}

.custom-link-active {
  color: #2d6aa0;
}

.custom-link-active:hover {
  color: #2d6aa0;
  text-decoration: none;
}

.custom-link-next {
  color: #5a5858;
}

.custom-link-next:hover {
  color: #5a5858;
  text-decoration: none;
}

.custom-link-prev {
  color: #999;
}

.custom-link-prev:hover {
  color: #999;
  text-decoration: none;
}

.custom-link:hover {
  color: #2d6aa0;
  text-decoration: none !important;
}

/* -------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
:root {
  --line-border-fill: #2d6aa0;
  --line-border-empty: #e1e1e1;
}

.progress-body {
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.progress-container::before {
  content: "";
  background: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 350px;
  z-index: 1;
}

.progress {
  background: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  background: #fff;
  color: #999;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
}

.circle.active {
  border-color: var(--line-border-fill);
}

.btn {
  background-color: var( --line-border-fill);
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  border: 0;
  border-radius: 6px;
  padding: 8px 30px;
  margin: 5px;
  font-size: 14px;
}

.btn:active {
  transform: scale(0.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: var( --line-border-empty);
  cursor: not-allowed;
}

.file-border {
  border-style: dashed;
  border-width: 1px;
  border-color: #999;
  height: auto;
  background-color: #fff;
  text-align: center;
}

.file-border i {
  font-size: 50px;
  color: #999;
}

.file-border label {
  display: inline-block;
  background-color: none;
  color: #2d6aa0;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 0.5rem;
  font-weight: 600;
}

.card-grey {
  background-color: #fff;
  color: #5a5858;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
}

.docs-box {
  height: 120px;
  width: 140px;
  border: 1px dashed #a8a8a8;
  text-align: center;
  padding: 1rem;
  color: #999;
  text-decoration: none;
  vertical-align: middle;
}

.docs-box-hover:hover {
  color: #2d6aa0 !important;
  text-decoration: none;
}

/* custom scroll bar */

.main-kycPanel::-webkit-scrollbar {
  width: 0.3em;
  height: 0.2em;
}

.main-kycPanel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.main-kycPanel::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.docs-box::-webkit-scrollbar {
  width: 0.5em;
}

.docs-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.docs-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* otp model css */

.otp-textField {
  font-size: 20px;
  padding: 10px;
  background-color: #ecebeb;
  border: none;
  border-bottom: 1px solid #a8a8a8;
  text-align: center;
  color: #5a5858;
}