.assetHeader {
    margin: 0 5rem 3rem !important;
}

.assetHeaderImg {
    width: 35% !important;
    object-fit: contain !important;
}

.assetMain {
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
}

.assetCard {
    background: var(--white);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 0 1.5rem !important;
    height: 100%;
    text-align: center !important;
}

.assetCardTopDiv {
    padding: 1rem 0.5rem !important;
    border-bottom: 2px solid var(--shade-cdcbcb);
    min-height: 12rem !important
}

.assetCardHR {
    border: none;
    border-left: 2px solid var(--shade-cdcbcb) !important;
    width: 1px;
}

.assetCardBottom {
    min-height: 5rem !important;
}

.assetCardBottomNumber {
    font-weight: 500 !important;
    word-break: break-all;
}

.assetCardBottomLeft {
    padding: 1rem 0.5rem 0 !important;
    text-align: left !important;
    border-right: 1px solid var(--shade-cdcbcb) !important;
}

.assetCardBottomRight {
    padding: 1rem 1rem 0 !important;
    text-align: left !important;
    border-left: 1px solid var(--shade-cdcbcb) !important;
}

.assetCardImg {
    width: 4rem;
    object-fit: contain !important;
    margin: 0.5rem !important;
}

.assetCardOppBtnDiv {
    text-align: center !important;
    display: flex;
    justify-content: center;
    padding: 1rem 0 !important;
}

#assetOpp {
    margin-bottom: 0 !important;
    padding-bottom: 2rem !important;
}

#assetOpp .assetCardOppBtnDiv {
    justify-content: left !important;
    padding: 0 !important;
}

#assetOpp .assetCardTopDiv {
    min-height: 10rem !important;
}

#assetOpp .refData {
    margin-top: 1rem !important;
}

.assetCardOppBtn {
    padding: 0.5rem 2.5rem !important;
    background: var(--primary);
    color: var(--white);
    box-shadow: 0px 2.63736px 2.63736px rgb(0 0 0 / 24%);
    border-radius: 5px;
    width: max-content;
}

.assetOppBgImg {
    width: 100%;
    position: fixed;
    top: 35%;
    z-index: -1;
}

.assetOppImg {
    width: 100% !important;
    object-fit: contain !important;
    height: 4rem !important;
}

#assetDetail .assetOppImg {
    height: 8rem !important;
}

#assetOpp .assetCardBottom {
    padding: 1rem 0.5rem !important;
    text-align: left !important;
}

.assetOppSector {
    padding: 1rem 0 !important;
}

.assetOppSectorValue {
    padding: 0 1rem !important;
    background: var(--primary);
    color: var(--white);
    border-radius: 25px;
    max-width: max-content !important;
    margin-top: 0.5rem !important;
    word-break: break-word;
}

#faq {
    margin-top: 2rem !important;
    border-top: 2px solid var(--primary);
    padding: 1rem 0 3rem !important;
    margin-bottom: 0 !important;
}

.assetDetailMain {
    background-color: var(--shade-EBEBEB);
}

.assetBgImage {
    background-image: url("../images/assetBg.svg");
    background-size: cover;
    background-position: 0 8rem;
    background-repeat: no-repeat;
    min-height: 100%;
}

.assetDetailBg {
    border-radius: 15px !important;
    background-color: var(--white);
    height: fit-content;
}

.assetDetailHeader {
    background-color: var(--line-border-empty);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1rem 2rem !important;
}

.assetDetailHeaderSub {
    color: var(--shade-AEAEAE);
    padding-bottom: 1rem !important;
}

.assetDetailTab {
    background-color: var(--shade-cdcbcb);
    padding-top: 1.5rem !important;
    display: flex;
    justify-content: space-around;
}

.assetDetailTabSelected {
    border-bottom: 3px solid var(--primary);
    padding-bottom: 0.5rem !important;
}

.assetDetailPriceCard {
    padding: 2rem 1rem !important;
}

.assetDetailPriceCardTitle {
    font-weight: 500 !important;
}

.assetDetailInvestBtn {
    margin-top: 1.5rem !important;
    padding: 0.5rem 0 !important;
    text-align: center !important;
    background: var(--primary);
    color: var(--white);
    box-shadow: 0px 2.63736px 2.63736px rgb(0 0 0 / 24%);
    border-radius: 5px;
}

.videoDisplayParent {
    width: 100%;
    max-height: 50%;
    margin-bottom: 1rem;
    padding-top: 56.25%;
    position: relative
}

.videoDisplay {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#fttSectionData img {
    width: 100% !important;
    height: auto !important;
}

#fttSectionData iframe {
    width: 100% !important;
    height: auto !important;
}

.paymentLink {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

@media only screen and (max-width: 992px) and (min-width: 769px) {
    .assetHeader {
        margin: 0 2rem 3rem !important;
    }

    .assetBgImage {
        height: 100% !important;
    }

    .assetHeaderImg {
        object-fit: contain !important;
    }

    .assetCard {
        margin: 0 1rem !important;
    }

    .assetCardTopDiv {
        padding: 1rem 0.5rem !important;
    }

    .assetCardBottom {
        min-height: 7rem !important;
    }

    .assetCardBottomRight {
        padding: 1rem 0.5rem !important;
    }

    .assetCardBottomLeft {
        padding: 1rem 0.5rem !important;
    }

    .assetOppBgImg {
        top: 15%;
    }
}

@media only screen and (max-width: 768px) {

    .assetMain {
        width: 100% !important;
    }

    .assetBgImage {
        background: none !important;
    }

    .assetHeader {
        margin: 0 0 1rem !important;
    }

    .assetHeaderImg {
        object-fit: contain !important;
        margin-top: 1rem !important;
    }

    .assetCard {
        margin: 0 !important;
        text-align: left !important;
    }

    .assetCardTopDiv {
        padding: 1rem 0.5rem !important;
        min-height: 0 !important;
        height: auto !important;
    }

    .assetCardBottom {
        min-height: 0 !important;
        height: auto !important;
    }

    .assetCardBottomRight {
        padding: 1rem 1rem !important;
    }

    .assetCardBottomLeft {
        padding: 1rem 1rem !important;
    }

    .assetOppBgImg {
        display: none;
    }

    #assetDetail .assetOppImg {
        height: 4rem !important;
    }

    #faq {
        padding-bottom: 1rem !important;
    }

    .assetDetailHeader {
        padding: 1rem 1rem !important;
    }

    .assetDetailTab {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding-bottom: 1rem !important;
    }

    .assetDetailTabSelected {
        width: max-content;
    }

    .tabWidth {
        margin: 0 1rem;
        width: max-content;
    }
}


#faq .MuiAccordion-root {
    margin-bottom: 0.5rem !important;
}

#faq .MuiAccordionDetails-root {
    padding-left: 8px !important;
}

#faq .MuiAccordionSummary-root {
    padding-left: 0 !important;
}

#faq .MuiAccordionSummary-content {
    margin: 0 !important;
}

#faq .MuiAccordionSummary-content .MuiTypography-body1 {
    font-weight: bold !important;
}

#faq .MuiAccordionSummary-content.Mui-expanded {
    padding-top: 16px !important;
}

#faq .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}

#faq .MuiPaper-elevation1 {
    box-shadow: none !important;
}

#faq .MuiPaper-root {
    background-color: unset !important;
}

#faq .MuiAccordion-root:before {
    background-color: unset !important;
}

.questionMark {
    margin: 0 0.3rem !important;
    background-color: var(--primary);
    color: var(--white);
    padding: 0.1rem 0.2rem 0.15rem;
    border-radius: 30%;
}

.custProfileUl {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.custProfileUl a {
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    margin: 0 0.5rem;
    white-space: nowrap;
    color: black !important;
}

.custProfileUlActive {
    border-bottom: 4px solid var(--primary);
}