.mainDiv {
    margin: 1rem 4rem;
    font-family: Poppins;
}

.animBtn {
    background-color: var(--line-border-fill);
    color: #fff;
    font-family: inherit;
    border: 0;
    border-radius: 6px;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.lightColorText {
    color: #AEAEAE;
}

.welcomeLoginForm {
    margin-left: 4rem;
    margin-right: 2rem;
}

.welcomeImg {
    margin-left: 3rem;
}

.welcome-heading {
    font-size: 2.1rem !important;
    font-weight: 700;
}

.welcome-sub-heading {
    top: -10px !important;
    color: #AEAEAE !important;
    position: relative !important;
    line-height: 1.2;
    margin-top: 0.5rem !important;
}

.loginForm {
    margin-top: 2rem;
    margin-right: 4rem;
}

.login-screen-input {
    padding-left: 10px;
    line-height: 2 !important;
    background-color: transparent;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    align-items: center;
    display: flex;
}

.signIn-button {
    padding-left: 10px;
    line-height: 2 !important;
    background-color: #009FE3;
    color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(0, 159, 227, 0.25);
    align-items: center;
    width: 100%;
    font-size: 1.2rem;

}

.googleSignIn-button {
    padding-left: 10px;
    line-height: 2.8 !important;
    background-color: transparent;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 700;
}

.noAccount {
    color: #AEAEAE;
    font-weight: bold;
    text-align: center;
}

.investDiv {
    /* margin-top: 3rem; */
    margin-left: 4rem;
    margin-right: 4rem;
    /* margin-bottom: 4rem; */
    text-align: center;
    display: block;
}

.investDetail {
    color: #AEAEAE;
    font-size: 1.2rem;
}

.featuredInImage {
    width: 100%;
    object-fit: contain;
    height: 3rem;
}

.howItWorksBox {
    justify-content: right !important;
    display: flex;
}

.aboutCont {
    margin-right: 2rem;
}

.aboutTextpace {
    margin: 4rem 0rem !important;
}

.howItWorks {
    width: 80%;
    border: 2px solid #EBEBEB;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.howItWorksCont {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
}


.howItWorksStepLine {
    border-left: 2px solid #EBEBEB;
    height: 5rem;
    margin-left: 1.7rem !important;
    display: flex;
    align-items: center;
}

.howItWorksStepCircle {
    height: 10px;
    width: 10px;
    background-color: #EBEBEB;
    border-radius: 50%;
    position: relative;
    left: -6px;
}

.getInTouch {
    font-size: 0.9rem;
    margin: 3rem 10rem;
}

.getInTouchIcon {
    width: unset;
}

.footer {
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
    display: block;
}

.footerHeading {
    background: linear-gradient(135deg, #009FE3 0%, #009FE3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold;
}

.footerConv {
    margin-top: 0rem;
}

.hr {
    height: 0.5px;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 50.52%, rgba(0, 0, 0, 0) 100%);
    margin-top: 3rem !important;
}

.arrowDivLeft {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 25%;
}

.arrowDivRight {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 60%;
}

.blueBack {
    position: absolute;
    width: 50%;
    height: 15rem;
    background-color: rgb(161, 217, 244, 0.4);
    filter: blur(8rem);
    border-radius: 65rem;
    z-index: -1;
    right: 0;
}

.redBack {
    position: absolute;
    width: 50%;
    height: 15rem;
    background-color: rgb(237, 31, 36, 0.08);
    filter: blur(8rem);
    border-radius: 65rem;
    z-index: -1;
    left: 0px;
}

.distDetailBannerBg {
    background: rgba(248, 248, 248, 0.77);
    backdrop-filter: blur(9px);
    border-radius: 16px;
    width: 45vw;
    z-index: 99;
    margin-left: 4rem !important;
    position: relative;
    font-family: Poppins !important;
    padding: 2rem !important;

}

.distDetailBannerTitle {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
}

.distDetailBannerBgP {
    padding-top: 1rem !important;
    font-size: 1.2rem !important;
}

.distDetailBannerul {
    padding-inline-start: 20px !important;
}

.partnerUsBtn {
    background-color: #69CDF9;
    color: #FFFFFF;
    border-color: #69CDF9;
    border-radius: 5px;
    padding: 0.5rem 1.5rem !important;
    font-weight: bold;
    font-size: 1.2rem !important;
}

.getTouchBtn {
    background-color: #FFFFFF;
    color: #69CDF9;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 0.5rem 1.5rem !important;
    font-weight: bold;
    font-size: 1.2rem !important;
}

.partnerWithCard {
    background: #FFFFFF;
    box-shadow: 4.06905px 16.2762px 40.6905px rgba(74, 153, 211, 0.2);
    border-radius: 8.1381px;
    margin: 2rem 2rem 0.5rem 2rem !important;
    padding: 0rem 2rem 2rem 2rem !important;
    font-size: 0.7rem !important;
    height: 90%;
}

.partnerWithCardTitle {
    font-size: 0.9rem !important;
}

.partnerWithCardIcon {
    object-fit: contain;
    width: 8rem;
    height: 8rem;
}

.partnerWithImage {
    object-fit: contain;
    width: 100% !important;
}

.ourPartner {
    background: rgba(74, 153, 211, 0.1) !important;
    margin: 5rem 0 !important;
    padding: 3rem !important;
}

.ourPartnerIcon {
    object-fit: contain;
    width: 4rem !important;
    height: 4rem !important;
}

.ourPartnerTitle {
    padding-top: 0 !important;
    font-size: 1.5rem !important;
}

.aboutCard {
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 1.5rem !important;
    padding: 2rem 2rem 2rem 2rem !important;
    font-size: 0.7rem !important;
}

.aboutCardTitle {
    padding: 2rem 0 0 0 !important;
    font-size: 1.2rem !important;
    color: rgba(0, 41, 71, 0.59);
    font-weight: bold !important;
}

.aboutCardIcon {
    object-fit: contain;
    width: 4rem;
    height: 4rem;
}

.howDoesNumber {
    background-color: #69CDF9;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: bold;
}

.getTouchPad {
    padding: 1.5rem 1.5rem !important;
    height: 100%;
    border: 1px solid #F9FBFB;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    background-color: #FFFFFF;

}

.touchPadding {
    padding: 3rem 3rem 1.5rem 3rem !important;
}

.sendBtn {
    background-color: #69CDF9;
    color: #FFFFFF;
    width: 100%;
    line-height: 2.5;
    text-align: center;
    border-radius: 5px;
    font-size: 1rem;
}

.faqOpen {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    background-color: #52a952;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 1.5rem !important;

}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;

    border-top: 1rem solid #ebebeb;
    position: fixed;
    bottom: 4rem;
    right: 2.5rem;
    z-index: 9999;
}

.faqMain {
    bottom: 4.75rem;
    right: 2rem;
    position: fixed;
    z-index: 9999;
    width: 40vw;
    height: 70vh;
    border: 1px solid #ebebeb;
    border-top: 3px solid #52a952;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.faqWA {
    background-color: antiquewhite;
    margin-bottom: 2rem;
    padding: 2rem 1rem;
    text-align: center;
}

.faqWAImg {
    width: 3rem !important;
}

.faqSet {
    padding: 0 1.5rem 2rem !important;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.faqSet::-webkit-scrollbar {
    display: none;
}

#faq .MuiAccordion-root {
    margin-bottom: 0.5rem !important;
}

#faq .MuiAccordionSummary-content {
    margin: 0 !important;
}

#faq .MuiAccordionSummary-content .MuiTypography-body1 {
    font-weight: bold !important;
}

#faq .MuiAccordionSummary-content.Mui-expanded {
    padding-top: 16px !important;
}

#faq .MuiTypography-body1 {
    font-size: 0.7rem !important;
}

#faq .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}

@media only screen and (max-width: 992px) {

    .distDetailBannerBg {
        background: rgba(248, 248, 248, 0.77);
        backdrop-filter: blur(9px);
        border-radius: 16px;
        z-index: 99;
        margin: 1.5rem !important;
        position: relative;
        font-family: Poppins !important;
        padding: 1rem !important;
    }

    .distDetailBannerTitle {
        font-size: 1.4rem !important;
    }

    .distDetailBannerBgP {
        padding-top: 1rem !important;
        font-size: 0.9rem !important;
    }

    .distDetailBannerul {
        padding-inline-start: 20px !important;
    }

    .partnerUsBtn {
        font-size: 0.9rem !important;
    }

    .getTouchBtn {
        font-size: 0.9rem !important;
    }

    .mainDiv {
        margin: 1rem 2rem;
    }

    .welcomeLoginForm {
        margin-left: 2rem;
        margin-right: 0rem;
    }

    .welcome-heading {
        font-size: 1.6rem !important;
    }

    .welcome-sub-heading {
        top: -5px !important;
        position: relative !important;
        line-height: 1.2;
    }

    .loginForm {
        margin-top: 2rem;
        margin-right: 1rem;
    }

    .welcomeImg {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .investDiv {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .investDetail {
        font-size: 0.8rem;
    }

    .howItWorks {
        width: 100%;
    }

    .howItWorksCont {
        margin-left: 1rem;
    }

    .getInTouch {
        font-size: 0.9rem;
        margin: 3rem 4rem;
    }

    .footer {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .blueBack {
        height: 25rem;
    }

    .redBack {
        height: 25rem;
    }

    .partnerWithCard {
        margin: 2rem 1rem 0.5rem 1rem !important;
        padding: 0rem 1rem 1rem 1rem !important;
        font-size: 0.5rem !important;
        height: 80% !important;
    }

    .partnerWithCardTitle {
        font-size: 0.7rem !important;
    }

    .partnerWithCardIcon {
        width: 6rem !important;
        height: 6rem !important;
    }

    .aboutCard {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        margin: 1rem !important;
        padding: 1.5rem !important;
        font-size: 0.7rem !important;
    }

    .aboutCardTitle {
        padding: 2rem 0 0 0 !important;
        font-size: 1.2rem !important;
        color: rgba(0, 41, 71, 0.59);
        font-weight: bold !important;
    }

    .aboutCardIcon {
        object-fit: contain;
        width: 4rem;
        height: 4rem;
    }

    .touchPadding {
        padding: 1.5rem !important;
    }

    .faqMain {
        width: 60vw !important;
        height: 60vh !important;
    }

    .faqSet {
        padding: 0 1rem 1rem !important;
    }

    #faq .MuiTypography-body1 {
        font-size: 1rem !important;
    }

}

@media only screen and (max-width: 768px) {
    .distDetailBannerBg {
        background: rgba(248, 248, 248, 0.77);
        backdrop-filter: blur(9px);
        border-radius: 16px;
        width: unset !important;
        z-index: 99;
        margin: 1rem !important;
        position: relative;
        font-family: Poppins !important;
        padding: 1rem !important;

    }

    .distDetailBannerTitle {
        font-size: 1.2rem !important;
    }

    .distDetailBannerBgP {
        padding-top: 1rem !important;
        font-size: 0.9rem !important;
    }

    .distDetailBannerul {
        padding-inline-start: 20px !important;
    }

    .partnerUsBtn {
        font-size: 0.9rem !important;
        font-weight: unset !important;
        padding: 0.5rem 0.7rem !important;
    }

    .getTouchBtn {
        font-size: 0.9rem !important;
        font-weight: unset !important;
        padding: 0.5rem 0.7rem !important;
    }

    .mainDiv {
        margin: 1rem 1rem;
    }

    .welcomeLoginForm {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .welcome-heading {
        font-size: 1.4rem !important;
    }

    .welcome-sub-heading {
        top: -5px !important;
        position: relative !important;
        line-height: 1.2;
    }

    .loginForm {
        margin-top: 2rem;
        margin-right: 1rem;
    }

    .welcomeImg {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .investDiv {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .investDetail {
        text-align: justify;
    }

    .aboutCont {
        margin-right: 0rem;
    }

    .aboutTextpace {
        margin: 2rem 0rem !important;
    }

    .getInTouch {
        font-size: 0.6rem;
        margin: 1.5rem 0rem;
    }

    .getInTouchIcon {
        width: 1.8rem;
    }

    .footer {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .footerConv {
        margin-top: 2rem !important;
    }

    .blueBack {
        height: 35rem;
    }

    .redBack {
        height: 35rem;
    }

    .partnerWithCard {
        margin: 0.5rem 0.5rem 0.5rem 0.5rem !important;
        padding: 0rem 0.5rem 0.5rem 0.5rem !important;
        font-size: 0.5rem !important;
        height: 90% !important;
    }

    .partnerWithCardTitle {
        font-size: 0.7rem !important;
    }

    .partnerWithCardIcon {
        width: 4rem !important;
        height: 4rem !important;
    }

    .ourPartner {
        padding: 1rem !important;
    }


    .ourPartnerIcon {
        object-fit: contain;
        width: 2rem !important;
        height: 2rem !important;
    }

    .ourPartnerTitle {
        padding-top: 0.5rem !important;
        font-size: 1rem !important;
    }

    .aboutCard {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        margin: 0.5rem !important;
        padding: 1rem !important;
        font-size: 0.7rem !important;
        height: 90%;
    }

    .cardWidth {
        width: 50%;
    }

    .aboutCardTitle {
        padding: 1rem 0 0 0 !important;
        font-size: 0.9rem !important;
        color: rgba(0, 41, 71, 0.59);
        font-weight: bold !important;
    }

    .aboutCardIcon {
        object-fit: contain;
        width: 2rem;
        height: 2rem;
    }

    .getTouchPad {
        padding: 1.5rem 0 !important;
    }

    .touchPadding {
        padding: 1rem !important;
    }

    .faqOpen {
        bottom: 4rem !important;
        right: 0.5rem !important;
    }

    .faqMain {
        width: 95vw !important;
        height: 70vh !important;
        bottom: 7.75rem !important;
        right: 0.5rem !important;
    }

    .arrow-down {
        bottom: 7rem;
        right: 1rem;
    }

    .faqSet {
        padding: 0 1rem 1rem !important;

    }

    #faq .MuiTypography-body1 {
        font-size: 0.8rem !important;
    }
}

.loginForm .MuiInput-underline::before {
    border-bottom: 0px !important;
}

.loginForm .MuiInput-underline::after {
    border-bottom: 0px !important;
}

/* -------------------------------------------------old=------------------------------------ */
/* .login-screen-form-wrapper{
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 10px;
}
.login-screen-wallpaper {
    height: 100%;
}
.login-screen-wallpaper > img {
    object-fit: cover;
}
.login-screen-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 150px;
}
.login-screen-input {
    padding-left: 0;
    margin-bottom: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #E1E1E1;
    border-radius: 0;
}
.login-screen-input:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.login-screen-hr {
    margin: 0% !important;
}
.login-screen-hr-or {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
}
.login-screen-hr-or > p {
    position: absolute;
    width: 80px;
    padding: 0;
    margin: 0;
    top: -10px;
    background-color: white;
}

.google-sign-in-button {
    background-color: #f2f2f2;
    padding: 8px 30px;
    border-radius: 30px;
    white-space: normal !important;
    word-wrap: break-word;
}
.google-sign-in-button > img {
    width: 20px;
    margin-bottom: 3px;
    margin-right: 5px;
}
@media screen and (max-width: 576px) {
    .login-screen-sign-in-button {
        width: 100%;
        margin: auto;
        padding: 10px 0px;
        margin: 0px 0px 20px 0px;
        border-radius: 30px !important;
    }
} */